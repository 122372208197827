// export * from './storage'
export const isEqualObject = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const checkFormError = async (inputValue, schema) => {
  try {
    const validationResult = await schema.validate(inputValue, {
      abortEarly: false,
    });
    return !!validationResult;
  } catch (error) {
    let obj = {};

    error.inner.forEach((vr) => {
      obj[vr.path] = vr.errors[0];
    });
    return obj;
  }
};

export const getOptionError = (option, result, field) => {
  const optionError = option.map((item, ind) => {
    let obj = {};
    Object.keys(item).map((key) => {
      if (result[`${field}[${ind}].${key}`]) {
        obj[key] = result[`${field}[${ind}].${key}`];
      }
    });
    return obj;
  });
  return optionError;
};

export const getResposeError = (resError) => {
  let error = "";
  for (const key in resError) {
    if (Object.hasOwnProperty.call(resError, key)) {
      error =
        typeof resError[key] === "string" ? resError[key] : resError[key][0];
    }
  }
  return error;
};

export const todayDate = new Date().toISOString().split("T")[0];

export const firstLetterCapital = (word) =>
  word.length > 1
    ? word.charAt(0).toUpperCase() + word.slice(1)
    : word.toUpperCase();

export const queryString = (params) =>
  Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

export const utils = {
  isEqualObject: (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2),

  isObjectValueEmpty: (obj) =>
    Object.values(obj).every((item) => item !== "") ? true : false,

  isObjectKeyEmpty: (obj) => (Object.keys(obj)?.length ? false : true),
};


export function formatDate(date = "", join = "/") {
  if (!date) {
    return "";
  }
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");

  return `${year}${join}${month}${join}${day}`;
}

export function formatDateList(date = "", join = "/") {
  if (!date) {
    return "";
  }
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");

  return `${day}${join}${month}${join}${year}`;
}

export * from "./ToastContext";
export * from "./constant";


export const getTimeAmPm = (date, join = "/") => {

  if (!date) {
    return "";
  }
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12-hour format
  // const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${day}${join}${month}${join}${year} | ${formattedHours}:${formattedMinutes} ${ampm}`;

  // return `${day}${join}${month}${join}${year} | ${formattedHours}:${formattedMinutes}`;
}

export const getDateTime =(date, join = "/")=>{
  const year = date.getFullYear();
const month = date.getMonth() + 1; // Months are zero-based, so add 1
const day = date.getDate();
const hours = date.getHours();
const minutes = date.getMinutes();
const seconds = date.getSeconds();

return `${year}${join}${month}${join}${day} ${hours}:${minutes}:${seconds}`;


return 
}


export function handleKeyDown(event) {

  function isValidNumber(val) {
    return !!(!isNaN(val) && !isNaN(parseInt(val)));
  }
  function isSpace(str) {
    return str === " ";
  }
  function isWhitelistedKey(key) {
    const whitelistedActions = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
      "Delete",
      "." //decimal separator
    ];
    return whitelistedActions.includes(key);
  }
  if (isWhitelistedKey(event.key)) {
    // let it through
  } else if (isSpace(event.key) || !isValidNumber(event.key)) {
    event.preventDefault();
  }

}


export function dateForXlsx() {

  const currentDate = new Date();

  // Get day, month, and year
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
  const year = currentDate.getFullYear().toString().slice(2);

  // Format day and month to have leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Formatted date in dd/mm/yyyy format
  const formattedDate = `${formattedDay}${formattedMonth}${year}`;

  return formattedDate; // Output: dd/mm/yyyy format of current date
}


