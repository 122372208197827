import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getStorage } from "../helper/storage";
import Header from "../Component/Layout/Header";
import Sidebar from "../Component/Layout/Sidebar";
import classNames from "classnames";
import { getAllAppDetailsApi } from "../services/voterService";
import UserContext from "../helper/UserContext";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export const ProtectRoutes = ({ children }) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { user, setUser } = useContext(UserContext);
  const loggedIn = getStorage("logged-in");
  useEffect(() => {
    if(loggedIn){
      getAllAppDetails(loggedIn);
    }
   
  }, []);

  console.log("loggedIn", loggedIn);

  const getAllAppDetails = async (data) => {
    const res = await getAllAppDetailsApi({
      app_name: data?.app_name,
      party_name: data?.party_name,
    });

    setUser({ ...user, appDetails: res?.results[0] });
  };

  console.log("user", user?.appDetails?.is_enable);

  if (loggedIn === null) {
    return <Navigate to={"/login"} />;
  }

  return (
    <>
      <div className="al_dashboard">
        <div className="d-flex">
          <Sidebar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
          <div className={classNames("content", { "is-open": sidebarIsOpen })}>
            <Header toggle={toggleSidebar} />
{loggedIn?.user_type !==1 ? <>
{user?.appDetails?.is_enable ? (
              <div className="_mainContainer">{children}</div>
            ) : (
              user?.appDetails && <div className="_mainContainer">
                <Modal
                  size="md"
                  isOpen={!user?.appDetails?.is_enable}
                  backdrop
                  keyboard
                  centered={true}
                >
                  <ModalHeader>App Important Message</ModalHeader>
                  <ModalBody> {user?.appDetails?.error_message} </ModalBody>
                </Modal>
              </div>
            )}
</> : <div className="_mainContainer">{children}</div>}
            
          </div>
        </div>
      </div>
    </>
  );
};
