import {
  Login,
  Dashboard,
  Orders,
  SortVoter,
  FullVoter,
  CastPro,

} from "../Pages";

export const routerList = [
  {
    path: "/login",
    element: Login,
    private: 0,
  },

  {
    path: "/dashboard",
    element: Dashboard,
    key: "dashboard",
    private: 1,
  },
  {
    path: "/voter",
    element: Orders,
    key: "voter",
    private: 1,
  },
  {
    path: "/app",
    element: SortVoter,
    key: "app",
    private: 1,
  },
  {
    path: "/fullVoter",
    element: FullVoter,
    key: "fullVoter",
    private: 1,
  },
  {
    path: "/cast_pro",
    element: CastPro,
    key: "cast_pro",
    private: 1,
  }
];

export { ProtectRoutes } from "./ProtectRoutes";
export { PublicRoute } from "./PublicRoute";
