import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const CheckboxField = (props) => {
  const { label, type, inputClass, boxClass, labelClass = "" } = props;

  return (
    <FormGroup check className={boxClass}>
      <Label className={`${labelClass}  pointer`} check>
        <Input {...props} className={`${inputClass}  pointer`} type={type} />
        {label}
      </Label>
    </FormGroup>
  );
};

export default CheckboxField;
