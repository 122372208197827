import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { icon } from "../Icon";
import { clearStorage } from "../../helper/storage";
import { AiOutlineMenu } from "react-icons/ai";
import ConfirmModal from "../Common/ConstomModal/ConfirmModal";
import InputModal from "../Common/ConstomModal/InputModal";
import { InputField } from "../Common/InputField";
import { checkFormError, getResposeError } from "../../helper";
import { forgetValidation, modalInputSchema, resetValidation } from "../../helper/errorSchema";
import { forgetApi,  resetPasswordApi } from "../../services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const Header = ({ toggle }) => {
  const [open, setOpen] = useState({
    logout: false,
    forget: false,
    reset: false,
    action: "",
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState({
    username: "",
    new_password:"",
    confirm_password:"",
    old_password:"",
    confirm_new_password:""

  });
  const [inputError, setInputError] = useState({
    username: "",
    new_password:"",
    confirm_password:"",
    old_password:"",
    confirm_new_password:""
  });
  const [isLoadding, setIsLoadding] = useState(false)

  const [isEdit, setIsEdit] = useState(false);

  const toggle1 = () => setDropdownOpen((prevState) => !prevState);
  const navigate = useNavigate();
  const handleLogout = () => {
    const callBack = () => {
      // navigate("/login");
    };
    clearStorage(callBack);
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalInputSchema);
      setInputError(error);
    }
  };

  const onSubmitForget = async () => {
    const validationResult = await checkFormError(
      { username: inputValue.username ,new_password: inputValue.new_password,confirm_password: inputValue.confirm_password},
     forgetValidation
    );
    if (validationResult === true) {
     setIsLoadding(true)
     forgetHandle( { username: inputValue.username ,new_password: inputValue.new_password,confirm_password: inputValue.confirm_password})
    } else {
      setInputError(validationResult);
      setIsLoadding(false)
    }
  };

  const onSubmitReset = async () => {
    const validationResult = await checkFormError(
      { old_password: inputValue.old_password ,new_password: inputValue.new_password,confirm_new_password: inputValue.confirm_new_password},
      resetValidation
    );
    if (validationResult === true) {
      setIsLoadding(true)
      resetHandle( { old_password: inputValue.old_password ,new_password: inputValue.new_password,confirm_new_password: inputValue.confirm_new_password})
    } else {
      setInputError(validationResult);
      setIsLoadding(false)
    }
  };

  const forgetHandle = (formData)=>{
    forgateMutaion.mutate(formData);
  }

  const forgateMutaion = useMutation(forgetApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
       
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const resetHandle = (formData)=>{
    resetMutaion.mutate(formData);
  }

  const resetMutaion = useMutation(resetPasswordApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    // queryClient.refetchQueries("getAllOrder");

    setOpen({
      ...open,
      logout:false,
      forget:false,
      reset:false
    });
    setIsLoadding(false);
    clearAll();
  };

  const clearAll = ()=>{
    setInputValue({
      username: "",
      new_password:"",
      confirm_password:"",
      old_password:"",
      confirm_new_password:""
    })

    setInputError("")
  }
  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };
  return (
    <>
      <Navbar className="navbar shadow-sm p-2 " expand="md">
        <div className="head_outDiv">
          <div className="">
            <Button className="anv_togleBtn" onClick={() => toggle()}>
              <AiOutlineMenu />
            </Button>
          </div>
          <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
            <DropdownToggle caret>{icon.LogoutIcon}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() =>
                  setOpen({ ...open, logout: true, action: "Logout" })
                }
              >
                Logout
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  setOpen({ ...open, forget: true, action: "Forget Password" })
                }
              >
                Forgate Password
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  setOpen({ ...open, reset: true, action: "Reset Password" })
                }
              >
                Reset Password
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {/* <div className="btn" onClick={() => setOpen(true)}>
             Log out
          </div> */}
        </div>
      </Navbar>

      <ConfirmModal
        isOpen={open.logout}
        toggle={() => setOpen(!open.logout)}
        onSubmit={() => handleLogout()}
        title="Logout"
        message="Are You want to logout"
      />
      <InputModal
        isOpen={open.forget}
        headerName={"Forget Password"}
        toggle={() => {setOpen(!open.forget)
           clearAll()}}
        submitLabel={"Continue"}
        onSubmit={(e) => onSubmitForget(e)}
        disabled={inputValue.name}
        // isLoadding={isLoadding}
        inputProps={
          <>
            <InputField
              className="modal_input"
              label={"Username"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Enter Username"
              name="username"
              focus={!!inputError.username}
              error={inputError.username}
              value={inputValue.username}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />
            <InputField
              className="modal_input"
              label={"New Password"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder=" Enter New Password"
              name="new_password"
              type="password"
              focus={!!inputError.new_password}
              error={inputError.new_password}
              value={inputValue.new_password}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />
            <InputField
              className="modal_input"
              label={"Confirm Password"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Enter Confirm Password"
              name="confirm_password"
              type="password"
              focus={!!inputError.confirm_password}
              error={inputError.confirm_password}
              value={inputValue.confirm_password}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />
          </>
        }
      />
       <InputModal
        isOpen={open.reset}
        headerName={"Reset Password"}
        toggle={() => {
          clearAll()
          setOpen(!open.reset)}}
        submitLabel={"Continue"}
        onSubmit={(e) => onSubmitReset(e)}
        // disabled={inputValue.name}
        // isLoadding={isLoadding}
        inputProps={
          <>
            <InputField
              className="modal_input"
              label={"Old Password"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Enter old password"
              name="old_password"
              type="password"
              focus={!!inputError.old_password}
              error={inputError.old_password}
              value={inputValue.old_password}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />
            <InputField
              className="modal_input"
              label={"New Password"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Enter New Password"
              type="password"
              name="new_password"
              focus={!!inputError.new_password}
              error={inputError.new_password}
              value={inputValue.new_password}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />
            <InputField
              className="modal_input"
              label={"Confirm Password"}
              labelClass="modal_label"
              labelSpan="*"
              labelSpanClass="style_for_star"
              placeholder="Enter Confirm Password"
              name="confirm_new_password"
              type="password"
              focus={!!inputError.confirm_new_password}
              error={inputError.confirm_new_password}
              value={inputValue.confirm_new_password}
              onChange={({ target: { name, value } }) =>
                handleOnChange(name, value)
              }
            />
          </>
        }
      />
    </>
  );
};

export default Header;
