import React, { useEffect, useState } from "react";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import { InputField } from "../../Component/Common/InputField";
import { toast } from "react-toastify";
import { Button, Label } from "reactstrap";

import { castandProfessionValidation, fleetModalSchema, modalFileSchema, modalInputSchema } from "../../helper/errorSchema";
import { breadcrumbsMenu, checkFormError } from "../../helper";
import { Loader } from "../../Component/Common/Loader";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import CustomPagination from "../../Component/Common/CustomPagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import Breadcrumbs from "../../Component/Common/Breadcrumbs";
import Dropdown from "../../Component/Common/Dropdown";
import CustomTable from "../../Component/Common/CustomTable";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import CheckboxField from "../../Component/Common/CheckboxField";
import {
  addFleetTypeApi,
  deleteFleetTypeApi,
  getFleetType,
  updateActiveFleetType,
  updateFleetTypeApi,
} from "../../services/fleetTypeService";
import mytruck from "../../../image/tanker.png"
import { addCastPro, deleteCastPro, getCastProfession, updateActiveCastPro, updateCastPro, uploadExcelCastProApi } from "../../services/serviceCastPro";
import { getStorage } from "../../helper/storage";
import { CustomReactTable } from "../../Component/Common/CustomReactTable";

const dummyDrop = [
  { label: "one", value: 1 },
  { label: "two", value: 2 },
  { label: "three", value: 3 },
];

const productList = [
  { label: "Liquid", value: 1 },
  { label: "Dry", value: 2 },
];

export const CastPro = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    name: "",
    ordering: "-id"
  };
  const userLogin = getStorage("logged-in");

  const [isOpen, setIsOpen] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [productType, setProductType] = useState(null);
  const [isLoadding, setIsLoadding] = useState(false)
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    limit: 20,
    ...obj,
  });
  const debouncedFilter = useDebounce(filter, 500);
  const [loaderForList, setLoaderForList] = useState(false)
  const { data, isSuccess } = useQuery(
    ["getCastProfession", debouncedFilter],
    () => getCastProfession(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );


  useEffect(() => {
    if (data) {
      setLoaderForList(false)
    } else {
      setLoaderForList(true)
    }
  }, [data])

  const [inputValue, setInputValue] = useState({
    caste_english: "",
    caste_hindi: "",
    profession_english: "",
    profession_hindi: "",
    assembly_number: "",
    excel_file:""
  });
  const [inputError, setInputError] = useState({
    caste_english: "",
    caste_hindi: "",
    profession_english: "",
    profession_hindi: "",
    assembly_number: "",
    excel_file:""
  });
  const [disableInp, setDisableInp] = useState({
    caste_english: "",
    caste_hindi: "",
    profession_english: "",
    profession_hindi: "",
    assembly_number: "",
    excel_file:""
  });

  const closeModal = () => {
    setIsOpen(true);
    setIsEdit(false);
    setInputValue("");
    setInputError("");
    setProductType(null);
  };

  const onSubmit = async () => {
    const validationResult = await checkFormError(
      {
        caste_english: inputValue.caste_english,
        caste_hindi: inputValue.caste_hindi,
        profession_english: inputValue.profession_english,
        profession_hindi: inputValue.profession_hindi,
        assembly_number: inputValue.assembly_number
      },
      castandProfessionValidation
    );
    if (validationResult === true) {
      setIsLoadding(true)
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleAdd();
      }
    } else {
      setIsLoadding(false)
      setInputError(validationResult);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, fleetModalSchema);
      setInputError(error);
    }
  };

  const handleAdd = () => {
    const payload = {
        caste_english: inputValue.caste_english,
        caste_hindi: inputValue.caste_hindi,
        profession_english: inputValue.profession_english,
        profession_hindi: inputValue.profession_hindi,
        assembly_number: inputValue.assembly_number
    };
    addMutation.mutate(payload);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };
  const handleEditAction = (item) => {
    setDisableInp({
        id:item?.uuid,
        caste_english: item.caste_english,
        caste_hindi: item.caste_hindi,
        profession_english: item.profession_english,
        profession_hindi: item.profession_hindi,
        assembly_number: item.assembly_number
    });
    setInputValue({
        id:item?.uuid,
        caste_english: item.caste_english,
        caste_hindi: item.caste_hindi,
        profession_english: item.profession_english,
        profession_hindi: item.profession_hindi,
        assembly_number: item.assembly_number
    });
    setProductType({
      label: item.product_type === 1 ? "Liquid" : "Dry",
      value: item?.product_type,
    });
    setIsOpen(true);
    setIsEdit(true);
    setInputError("");
  };

  const handleUpdate = () => {
    const payload = {
        id:inputValue.id,
        caste_english: inputValue.caste_english,
        caste_hindi: inputValue.caste_hindi,
        profession_english: inputValue.profession_english,
        profession_hindi: inputValue.profession_hindi,
        assembly_number: inputValue.assembly_number
    };
    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addCastPro, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsLoadding(false)
        onSuccessResponse(res);
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false)
      }
    },
  });

  const updateMutation = useMutation(updateCastPro, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setIsLoadding(false)
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false)
      }
    },
  });

  const deleteMutation = useMutation(deleteCastPro, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);
    setDeleteId(data);
  };

  const onSuccessResponse = (message) => {
    toast.success(message?.message ? message?.message : "success");
    queryClient.refetchQueries("getCastProfession");
    setIsUpload(false)
    setIsOpen(false);
  };

  const onErrorResponse = (error) => {
    toast.error(getResposeError(error ? error : "server error"));
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const updateTagStatus = (item) => {

    const payload = {
      id: item.id,
      is_active: !item?.is_active
    }
    updateActiveMutation.mutate(payload);
  }
  const updateActiveMutation = useMutation(updateActiveCastPro, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

 
  const column = [
    {
      Header: "S.No",
      accessor: "sno",
    },
    {
      Header: "Caste English",
      accessor: "caste_english",
    },
    {
      Header: "Caste Hindi",
      accessor: "caste_hindi",
    },
    {
      Header: "Profession English",
      accessor: "profession_english",
    },
    {
      Header: "Profession Hindi",
      accessor: "profession_hindi",
    },
    {
      Header: "Assembly Number",
      accessor: "assembly_number",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  const rowDataList = (data) => {
    const listArray = data?.map((item, i) => ({
      ...item,
      check: (
        <>
          <CheckboxField type="checkbox" name={item?.title} />
        </>
      ),
      product_type: <div>{item?.product_type === 1 ? "Liquid" : "Dry"}</div>,
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
      action: (
        <>
          <div className="actionBtn">
            <SwitchField
              onSwitchChange={() => updateTagStatus(item)}
              value={item.is_active}
            />
            <img src={EditIcon} alt="" onClick={() => handleEditAction(item)} />
            <img
              src={DeleteIcon}
              alt=""
              onClick={() => hendleDeleteTag(item, true)}
            />
          </div>
        </>
      ),
    }));
    return listArray;
  };

  
const onSubmitUpload = async ()=>{
  const validationResult = await checkFormError(
    { excel_file: inputValue.excel_file },
    modalFileSchema
);

const formData = new FormData();
formData.append("excel_file", inputValue?.excel_file);



if (validationResult === true) {
    setIsLoadding(true)
    if (isEdit === true) {
        handleUpdate();
    } else {
        handleUploadExcel(formData);
    }
} else {
    setIsLoadding(false)
    setInputError(validationResult);
}
}

const handleUploadExcel = (formData) => {

  uploadMutation.mutate(formData);
};

const uploadMutation = useMutation(uploadExcelCastProApi, {
  onSuccess: (res) => {
      if (res?.success) {

          onSuccessResponse(res?.results?.success);
      } else {
          setIsLoadding(false)
          onErrorResponse(res?.error);
      }
  },
});

  return (
    <>
      <div className="ques_container">
        <Breadcrumbs
          active="Cast And Profession"
          action={
            <div className="upload_button_ui">
            { userLogin?.user_type === 1 &&<button className="addPlan" onClick={() => setIsUpload(true)}>
            Upload 
          </button>}
          <button
             
              className="addPlan"
              onClick={() => closeModal()}
            >
              Add Cast And Profession
            </button>
            </div>
            
          }
        />
          
        <div className="anvTask_prog">
          <div className="anvInputs&fillters">
            {/* <div className="inr_flex_mini ">
              <Dropdown
                placeholder="Enable"
                option={dummyDrop}
                valueText="value"
                labelText="label"
                classNamePrefix="anvRc_controller"
              />
            </div>

            <div className="inr_flex">
              <InputField placeholder="Search" className="anvSearch_input" value={filter?.name}
                onChange={(e) =>
                  setFilter((s) => ({
                    ...s,
                    ...obj,
                    name: e.target.value,
                  }))
                } />
            </div> */}
          </div>
        </div>
        <div>
          <div className="table-responsive">
          <CustomReactTable
            columns={column}
            data={rowDataList(data?.results ? data?.results : [])}
            // tableLoading={loaderForList}
          />
            {/* <CustomTable
              column={column}
              data={rowDataList()}
              tableLoading={loaderForList}
            /> */}
          </div>
        </div>
      </div>

      {data?.results?.length === 0 ? (
        ""
      ) : (
        <CustomPagination
          onPageChange={handlePageClick}
          currentPage={filter?.currentPage}
          pageCount={Math.ceil(data?.count / filter?.limit)}
        />
      )}

      <InputModal
        isOpen={isOpen}
        size="lg"
        className="anvFleetType_modal"
        headerName={isEdit ? "Edit Cast And Profession" : "Add Cast And Profession"}
        toggle={() => setIsOpen(!isOpen)}
        submitLabel={isEdit ? "Update" : "Add"}
        onSubmit={(e) => onSubmit(e)}
        disabled={
          isEdit
            ? JSON.stringify(inputValue) === JSON.stringify(disableInp)
            : ""
        }
        isLoadding={isLoadding}
        inputProps={
          <>
            <div className="anvFlt_inrFx">
              <div className="anvBasis_flt">

                <InputField
                  className="modal_input"
                  // label={isEdit ? "Edit Document" : "Document Name"}
                  label={"Caste English"}
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Caste English"
                  name="caste_english"
                  focus={!!inputError.caste_english}
                  error={inputError.caste_english}
                  value={inputValue.caste_english}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                />
              </div>

              <div className="anvBasis_flt">

                <InputField
                  className="modal_input"
                  //   label={isEdit ? "Edit Document" : "Document Name"}
                  label={"Caste Hindi"}
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Caste Hindi"
                  name="caste_hindi"
                  focus={!!inputError.caste_hindi}
                  error={inputError.caste_hindi}
                  value={inputValue.caste_hindi}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                />
              </div>
            </div>
            <div className="anvFlt_inrFx">

              <div className="anvBasis_flt">
                <InputField
                  className="modal_input"
                  // label={isEdit ? "Edit Document" : "Document Name"}
                  label={"Profession English"}
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Enter Profession English"
                  name="profession_english"
                  focus={!!inputError.profession_english}
                  error={inputError.profession_english}
                  value={inputValue.profession_english}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                />
              </div>

              
              <div className="anvBasis_flt">

                <InputField
                  className="modal_input"
                  // label={isEdit ? "Edit Document" : "Document Name"}
                  label={"Profession Hindi"}
                  labelClass="modal_label"
                  labelSpan="*"
                  labelSpanClass="style_for_star"
                  placeholder="Profession Hindi"
                  name="profession_hindi"
                  focus={!!inputError.profession_hindi}
                  error={inputError.profession_hindi}
                  value={inputValue.profession_hindi}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                />
              </div>


            </div>
            <div className="anvFlt_inrFx">
            <div className="anvBasis_flt">

<InputField
  className="modal_input"
  // label={isEdit ? "Edit Document" : "Document Name"}
  label={"Assembly Number"}
  labelClass="modal_label"
  labelSpan="*"
  labelSpanClass="style_for_star"
  placeholder="Assembly Number"
  name="assembly_number"
  focus={!!inputError.assembly_number}
  error={inputError.assembly_number}
  value={inputValue.assembly_number}
  onChange={({ target: { name, value } }) =>
    handleOnChange(name, value)
  }
/>
</div>
            </div>
          
           
          </>
        }
      />
      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
      <InputModal
        isOpen={isUpload}
        headerName={isEdit ? "Upload" : "Upload"}
        toggle={() => setIsUpload(!isUpload)}
        submitLabel={isEdit ? "Upload " : "Upload"}
        onSubmit={(e) => onSubmitUpload(e)}
        disabled={isEdit ? inputValue.excel_file === disableInp.excel_file : ""}
        isLoadding={isLoadding}
        inputProps={

          <InputField
            className="modal_input"
            label={"File"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Select File"
            name="excel_file"
            type="file"
            focus={!!inputError.excel_file}
            error={inputError.excel_file}
            // value={inputValue.excel_file}
            onChange={({ target: { name, files } }) =>
            handleOnChange(name, files[0])
        }
          />
        }
      />
    </>
  );
};
