import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import { addBranchApi, getBranch, getTags } from "../../services";
import { toast } from "react-toastify";
import {
  addFullvoterValidation,
  addOrderValidation,
  addResearchSchema,
  addSortVoterValidation,
  editFullvoterValidation,
  modalFileSchema,
  modalInputSchema,
  productModalSchema,
} from "../../helper/errorSchema";
import {
  checkFormError,
  dateForXlsx,
  formatDate,
  formatDateList,
  fullVoterTabList,
  handleKeyDown,
  orderTabList,
  sortVoterTabList,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  deleteResearch,
  getAllCustomer,
  singleNewDetails,
  updateActiveResearch,
  updateResearch,
  uploadExcelCustomer,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import AllocateVehicleModal from "../../Component/Common/AllocateVehicleModal";
import { getDocumentType } from "../../services/documentTypeService";
import { getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import {
  acceptAndRejectStatus,
  addFleetsForOrder,
  addOrder,
  deleteOrder,
  getAllFleetforOrder,
  getAllOrder,
  getdryCargo,
  gettankersCsv,
  singleOrderDetails,
  updateActiveOrder,
  updateOrder,
} from "../../services/orderService";
import { addProductApi, getProduct } from "../../services/productService";
import { getAllDriver } from "../../services/driverService";
import mytruck from "../../../image/tanker.png";
import {
  getAllDrop,
  getAllPeckup,
  getAllPickup,
} from "../../services/locationService";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import { getAllRoute, singleRouteDetails } from "../../services/routeService";
import { components } from "react-select";
import { getUnit } from "../../services/unitService";
import CommonRoute from "../../Component/Common/CommonRoute";
import {
  addExitBorderApi,
  getExitBorder,
} from "../../services/exitBorderService";
import { CommonCustomer } from "../../Component/Common/CommonCustomer";
import { getStorage } from "../../helper/storage";
import { CustomReactTable } from "../../Component/Common/CustomReactTable";
import {
  addFullVoter,
  addSortVoter,
  deleteFullVoter,
  deleteFullVoterAc,
  deleteSortVoter,
  getAllBoothApi,
  getFullVoter,
  getSortVoter,
  singleFullVoter,
  singleSortVoter,
  updateActiveSortVoter,
  updateEpicFullVoter,
  updateFullVoter,
  updateSortVoter,
  uploadExcelVoterApi,
} from "../../services/voterService";
import { getCastProfession } from "../../services/serviceCastPro";
import UserContext from "../../helper/UserContext";

const productTypeList = [
  { label: "Liquid", value: 1 },
  { label: "Dry", value: 2 },
];

export const FullVoter = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "booth_number",
    customer_name: "",
  };
  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const fleetObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const productObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const customerObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const driverObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const routeObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const borderObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };

  const orderObj = {
    sr_number: "",
    assembly_number: "",
    booth_number: "",
    epic_number: "",
    voter_sr_number: "",
    house_number: "",
    age: "",
    full_name_english: "",
    full_name_hindi: "",
    first_name_english: "",
    first_name_hindi: "",
    middle_name_english: "",
    middle_name_hindi: "",
    last_name_english: "",
    last_name_hindi: "",
    gender_english: "",
    gender_hindi: "",
    address_english: "",
    address_hindi: "",
    booth_address_english: "",
    booth_address_hindi: "",
    mobile_number: "",
    colour: "",
    profession: "",
    updated_address: "",
    post: "",
    app_name: "",
    party_name: "",
    voter_type: 2,
    is_activist: false,
    is_voted: false,
  };
  const orderObjName = {
    sr_number: "",
    assembly_number: "",
    booth_number: "",
    epic_number: "",
    voter_sr_number: "",
    house_number: "",
    age: "",
    full_name_english: "",
    full_name_hindi: "",
    first_name_english: "",
    first_name_hindi: "",
    middle_name_english: "",
    middle_name_hindi: "",
    last_name_english: "",
    last_name_hindi: "",
    gender_english: "",
    gender_hindi: "",
    address_english: "",
    address_hindi: "",
    booth_address_english: "",
    booth_address_hindi: "",
    mobile_number: "",
    colour: "",
    profession: "",
    updated_address: "",
    post: "",
    app_name: "",
    party_name: "",
    voter_type: 2,
    is_activist: false,
    is_voted: false,
  };

  const userLogin = getStorage("logged-in");
  const { user, setUser } = useContext(UserContext);
  const result = userLogin?.user_branches?.join(",");
console.log("userLogin",userLogin)
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [openFleet, isOpenFleet] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [allotedisOpen, setAllotedisOpen] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [imageType, setimageType] = useState("");
  const [status, setStatus] = useState(1);
  // console.log(user?.appDetails?.assembly_number[0])
  const [filter, setFilter] = useState({
    limit: 25,
    status: status,
    assembly_number:
      userLogin?.user_type !== 1 ? user?.appDetails?.assembly_number[0] : "",
    booth_number: "",
    app_name:userLogin?.app_name,
    party_name:userLogin?.party_name,
    // view_booth_numbers: userLogin?.view_booth_permissions?.map((obj) => obj).join(","),
    // update_booth_numbers:userLogin?.update_booth_permissions?.map((obj) => obj).join(","),
    ...obj,
  });
  console.log(filter);
  const [filterModal, setFilterModal] = useState({
    limit: 100,
    status: status,
    ...obj,
  });

  const [formValue, setFormValue] = useState(formObj);
  const [initialForm, setInitialForm] = useState(orderObj);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [isLoadding, setIsLoadding] = useState(false);

  const [OrderID, setOrderID] = useState({
    id: 1,
    qty: "",
  });
  const [indexOfFleet, setIndexOfFleet] = useState();
  const debouncedFilter = useDebounce(filter, 500);
  const [loaderForList, setLoaderForList] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [all, setAll] = useState(false);
  const [epicNo, setEpicNo] = useState("");
  const [castList, setCastList] = useState([]);
  const [profList, setProfList] = useState([]);
  const [assamblyList, setAssamblyList] = useState([]);
  const [boothList, setBoothList] = useState([]);

  const [inputValue, setInputValue] = useState({
    name: "",
    ac_no: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
    ac_no: "",
  });
  const [disableInp, setDisableInp] = useState({
    name: "",
    ac_no: "",
  });

  const userTypeList = [
    { label: "ADMIN", value: 1 },
    { label: "VOLUNTEER", value: 2 },
  ];

  const colorLIst = [
    { label: "Green", value: 1 },
    { label: "Red", value: 2 },
    { label: "Yellow", value: 3 },
    { label: "Blue", value: 4 },
  ];

  const votedLIst = [
    { label: "Voted", value: 1 },
    { label: "Not Voted", value: 2 },
  ];

  const assmblyList = [
    { label: "20", value: 20 },
    { label: "46", value: 46 },
    { label: "50", value: 50 },
    { label: "6", value: 6 },
  ];

  const column = [
    {
      Header: "",
      accessor: "serial",
    },
    {
      Header: "Serial No.",
      accessor: "sr_number",
    },

    {
      Header: "Ac.No.",
      accessor: "assembly_number",
    },

    {
      Header: "Booth No.",
      accessor: "booth_number",
    },
    {
      Header: "V.S.No.",
      accessor: "voter_sr_number",
    },
    {
      Header: "H.No",
      accessor: "house_number",
    },
    {
      Header: "Full Name",
      accessor: "full_name_english",
    },
    {
      Header: "Full Name",
      accessor: "full_name_hindi",
    },
    {
      Header: "First Name",
      accessor: "first_name_english",
    },
    {
      Header: "First Name",
      accessor: "first_name_hindi",
    },
    // {
    //   Header: "Middle Name",
    //   accessor: "middle_name_english",
    // },
    // {
    //   Header: "Middle Name",
    //   accessor: "middle_name_hindi",
    // },

    {
      Header: "Relative Name",
      accessor: "last_name_english",
    },
    {
      Header: "Relative Name",
      accessor: "last_name_hindi",
    },
    {
      Header: "Gender",
      accessor: "gender_english",
    },
    {
      Header: "Gender",
      accessor: "gender_hindi",
    },
    {
      Header: "Age",
      accessor: "age",
    },
    {
      Header: "Mobile Numner",
      accessor: "mobile_number",
    },
    {
      Header: "Epic No",
      accessor: "epic_num",
    },
    // {
    //   Header: "Profession",
    //   accessor: "profession",
    // },
    // {
    //   Header: "Post",
    //   accessor: "post",
    // },
    {
      Header: "Colour",
      accessor: "colorCode",
    },
    {
      Header: "Booth Address",
      accessor: "booth_address_english",
    },
    // {
    //   Header: "Booth Address",
    //   accessor: "booth_address_hindi",
    // },
    // {
    //   Header: "Update Address",
    //   accessor: "updated_address",
    // },
    // {
    //   Header: "Activist",
    //   accessor: "activists",
    // },
    {
      Header: "Voted",
      accessor: "voteds",
    },

    {
      Header: "Action",
      accessor: "action",
    },
  ];
  const rowDataList = (data) => {
    // console.log(userLogin?.user_type == 2 ? data?.filter(item => userLogin?.update_booth_permissions.includes(item?.assembly_number)):"")
    //(userLogin?.user_type == 2 ? data?.filter(item => userLogin?.view_booth_permissions.includes(item?.assembly_number)):userLogin?.user_type == 3 ?data?.filter(item => userLogin?.view_booth_permissions.includes(item?.booth_number)):data)?
    const listArray = data.map((item, i) => ({
      ...item,
      serial: (
        <div className="serial_div">
          <div
            className={`color_div ${
              item?.colour == 1
                ? "Green"
                : item?.colour == 2
                ? "Red"
                : item?.colour == 3
                ? "Yellow"
                : item?.colour == 4
                ? "Blue"
                : ""
            }`}
          ></div>
        </div>
      ),
      activists: <>{item.is_activist === true ? "Yes" : "No"}</>,
      voteds: <>{item.is_voted === true ? "Yes" : "No"}</>,
      colorCode: (
        <>
          {item?.colour == 1
            ? "Green"
            : item?.colour == 2
            ? "Red"
            : item?.colour == 3
            ? "Yellow"
            : item?.colour == 4
            ? "Blue"
            : ""}
        </>
      ),
      epic_num: (
        <button
          onClick={() => {
            if (userLogin?.user_type == 1) {
              setEpicNo(item.epic_number);
              handleEditAction(item);
            }
          }}
        >
          {item?.epic_number}
        </button>
      ),

      action: (
        <>
          <div className="actionBtn">
            {/* {userLogin?.user_type === 3 ? (
              userLogin?.update_booth_permissions?.filter((val) =>
                val.includes(item?.booth_number)
              ).length > 0 && (
                <img
                  src={EditIcon}
                  alt=""
                  onClick={() => handleEditAction(item)}
                />
              )
            ) : userLogin?.user_type === 2 ? (
              userLogin?.update_booth_permissions?.filter((val) =>
                val.includes(item?.assembly_number)
              ).length > 0 && (
                <img
                  src={EditIcon}
                  alt=""
                  onClick={() => handleEditAction(item)}
                />
              )
            ): (
              <img
                src={EditIcon}
                alt=""
                onClick={() => handleEditAction(item)}
              />
            )} */}
             <img
                src={EditIcon}
                alt=""
                onClick={() => handleEditAction(item)}
              />
            {userLogin?.user_type !== 3 && (
              <img
                src={DeleteIcon}
                alt=""
                onClick={() => hendleDeleteTag(item, true)}
              />
            )}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getFullVoter", debouncedFilter],
    () => getFullVoter(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  useEffect(() => {
    getCastAndProList();
  }, []);

  useEffect(() => {
    if (filter?.assembly_number) {
      getBoothList(filter?.assembly_number);
    }
  }, [filter?.assembly_number]);

  useEffect(() => {
    if (userLogin?.user_type !== 1) {
      if (user?.appDetails) {
        const useDetails = user?.appDetails?.assembly_number?.map((i) => {
          return { label: i, value: i };
        });
        console.log("useDetails", useDetails);
        setAssamblyList(useDetails);
      }
    }
  }, [user?.appDetails]);

  //   useEffect(()=>{
  // console.log()
  //     setFilter((s) => ({
  //       ...s,
  //       ...obj,
  //       assembly_number:formOrderName?.assemblyFilter,
  //     }))
  //   },[formOrderName?.assemblyFilter])

  //   useEffect(()=>{
  // setFilter({...filter,assembly_number:formOrderName?.assemblyFilter?.map((item)=>item?.value).join(",")})
  //   },[formOrderName?.assemblyFilter])

  const getCastAndProList = async (item) => {
    const res = await getCastProfession();

    setCastList(
      res?.results?.map((item, index) => {
        return { label: item?.caste_english, value: item?.caste_english };
      })
    );
    setProfList(
      res?.results?.map((item, index) => {
        return {
          label: item?.profession_english,
          value: item?.profession_english,
        };
      })
    );
  };

  const getBoothList = async (as_number) => {
    if (as_number) {
      const res = await getAllBoothApi({ assembly_numbers: as_number });

      setBoothList(
        res?.results?.booth_numbers?.map((item, index) => {
          return { label: item, value: item };
        })
      );
    }
  };

  const clearAll = () => {
    setFormOrderValue({
      sr_number: "",
      assembly_number: "",
      booth_number: "",
      epic_number: "",
      voter_sr_number: "",
      house_number: "",
      age: "",
      full_name_english: "",
      full_name_hindi: "",
      first_name_english: "",
      first_name_hindi: "",
      middle_name_english: "",
      middle_name_hindi: "",
      last_name_english: "",
      last_name_hindi: "",
      gender_english: "",
      gender_hindi: "",
      address_english: "",
      address_hindi: "",
      booth_address_english: "",
      booth_address_hindi: "",
      mobile_number: "",
      colour: "",
      profession: "",
      updated_address: "",
      post: "",
      is_activist: false,
      is_voted: false,
      voter_type: 2,
    });
    setFormOrderName({
      sr_number: "",
      assembly_number: "",
      booth_number: "",
      epic_number: "",
      voter_sr_number: "",
      house_number: "",
      age: "",
      full_name_english: "",
      full_name_hindi: "",
      first_name_english: "",
      first_name_hindi: "",
      middle_name_english: "",
      middle_name_hindi: "",
      last_name_english: "",
      last_name_hindi: "",
      gender_english: "",
      gender_hindi: "",
      address_english: "",
      address_hindi: "",
      booth_address_english: "",
      booth_address_hindi: "",
      mobile_number: "",
      colour: "",
      profession: "",
      updated_address: "",
      post: "",
      is_activist: false,
      is_voted: false,
      voter_type: 2,
    });

    setIsEdit(false);
    setFormValue("");
    setFormError("");
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.id,
      is_activist: !item?.is_activist,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addFullvoterValidation);
      setValidationError(error);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalInputSchema);
      setInputError(error);
    }
  };
  const onSubmitUpload = async () => {
    const validationResult = await checkFormError(
      { excel_file: inputValue.excel_file },
      modalFileSchema
    );

    const formData = new FormData();
    formData.append("excel_file", inputValue?.excel_file);

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate();
      } else {
        handleUploadExcel(formData);
      }
    } else {
      setIsLoadding(false);
      setInputError(validationResult);
    }
  };

  const onSubmitDelete = () => {
    deleteAcMutation.mutate({ ac_no: inputValue?.ac_no, app_name:userLogin?.app_name,
      party_name:userLogin?.party_name, });
  };

  const handleUploadExcel = (formData) => {
    uploadMutation.mutate(formData);
  };

  const uploadMutation = useMutation(uploadExcelVoterApi, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.results?.success);
      } else {
        setIsLoadding(false);
        onErrorResponse(res?.error);
      }
    },
  });

  console.log(formOrderValue);
  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      formOrderValue.voter_type == 2
        ? addFullvoterValidation
        : editFullvoterValidation
    );

    const payload = {
      sr_number: formOrderValue.sr_number,
      assembly_number: formOrderValue?.assembly_number,
      booth_number: formOrderValue?.booth_number,
      epic_number: formOrderValue?.epic_number,
      voter_sr_number: formOrderValue?.voter_sr_number,
      house_number: formOrderValue?.house_number,
      booth_number: formOrderValue?.booth_number,
      age: formOrderValue?.age,
      full_name_english: formOrderValue?.full_name_english,
      full_name_hindi: formOrderValue?.full_name_hindi,
      first_name_english: formOrderValue?.first_name_english,
      first_name_hindi: formOrderValue?.first_name_hindi,
      middle_name_english: formOrderValue?.middle_name_english,
      middle_name_hindi: formOrderValue?.middle_name_hindi,
      last_name_english: formOrderValue?.last_name_english,
      last_name_hindi: formOrderValue?.last_name_hindi,
      gender_english: formOrderValue?.gender_english,
      gender_hindi: formOrderValue?.gender_hindi,
      address_english: formOrderValue?.address_english,
      address_hindi: formOrderValue?.address_hindi,
      booth_address_english: formOrderValue?.booth_address_english,
      booth_address_hindi: formOrderValue?.booth_address_hindi,
      mobile_number: formOrderValue?.mobile_number,
      colour: formOrderValue?.colour,
      profession: formOrderValue?.profession,
      updated_address: formOrderValue?.updated_address,
      post: formOrderValue?.post,
      app_name: formOrderValue?.app_name,
      party_name: formOrderValue?.party_name,
      voter_type: 2,
      is_voted: formOrderValue.is_voted,
      is_activist: formOrderValue.is_activist,
    };

    const payloadUpdate = {};

    Object.keys(formOrderValue).map((item, index) => {
      if (formOrderValue[item] !== initialForm[item]) {
        // formData.append(item, formOrderValue[item]);

        payloadUpdate[item] = formOrderValue[item];
      }
    });

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate(payloadUpdate);
      } else {
        handleAdd(payload);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const updateActiveMutation = useMutation(updateActiveSortVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleEditAction = (item) => {
    getOrderDetails.mutate(item?.uuid);
  };
  const getOrderDetails = useMutation(singleFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        setFormOrderValue({
          sr_number: res?.results?.sr_number,
          assembly_number: res?.results?.assembly_number,
          booth_number: res?.results?.booth_number,
          epic_number: res?.results?.epic_number,
          voter_sr_number: res?.results?.voter_sr_number,
          house_number: res?.results?.house_number,
          booth_number: res?.results?.booth_number,
          age: res?.results?.age,
          full_name_english: res?.results?.full_name_english,
          full_name_hindi: res?.results?.full_name_hindi,
          first_name_english: res?.results?.first_name_english,
          first_name_hindi: res?.results?.first_name_hindi,
          middle_name_english: res?.results?.middle_name_english,
          middle_name_hindi: res?.results?.middle_name_hindi,
          last_name_english: res?.results?.last_name_english,
          last_name_hindi: res?.results?.last_name_hindi,
          gender_english: res?.results?.gender_english,
          gender_hindi: res?.results?.gender_hindi,
          address_english: res?.results?.address_english,
          address_hindi: res?.results?.address_hindi,
          booth_address_english: res?.results?.booth_address_english,
          booth_address_hindi: res?.results?.booth_address_hindi,
          mobile_number: res?.results?.mobile_number,
          colour: res?.results?.colour,
          profession: res?.results?.profession,
          updated_address: res?.results?.updated_address,
          post: res?.results?.post,
          app_name: res?.results?.app_name,
          party_name: res?.results?.party_name,
          voter_type: res?.results?.voter_type,
          is_voted: res?.results?.is_voted,
          is_activist: res?.results?.is_activist,
        });

        setInitialForm({
          sr_number: res?.results?.sr_number,
          assembly_number: res?.results?.assembly_number,
          booth_number: res?.results?.booth_number,
          epic_number: res?.results?.epic_number,
          voter_sr_number: res?.results?.voter_sr_number,
          house_number: res?.results?.house_number,
          booth_number: res?.results?.booth_number,
          age: res?.results?.age,
          full_name_english: res?.results?.full_name_english,
          full_name_hindi: res?.results?.full_name_hindi,
          first_name_english: res?.results?.first_name_english,
          first_name_hindi: res?.results?.first_name_hindi,
          middle_name_english: res?.results?.middle_name_english,
          middle_name_hindi: res?.results?.middle_name_hindi,
          last_name_english: res?.results?.last_name_english,
          last_name_hindi: res?.results?.last_name_hindi,
          gender_english: res?.results?.gender_english,
          gender_hindi: res?.results?.gender_hindi,
          address_english: res?.results?.address_english,
          address_hindi: res?.results?.address_hindi,
          booth_address_english: res?.results?.booth_address_english,
          booth_address_hindi: res?.results?.booth_address_hindi,
          mobile_number: res?.results?.mobile_number,
          colour: res?.results?.colour,
          profession: res?.results?.profession,
          updated_address: res?.results?.updated_address,
          post: res?.results?.post,
          app_name: res?.results?.app_name,
          party_name: res?.results?.party_name,
          voter_type: res?.results?.voter_type,
          is_voted: res?.results?.is_voted,
          is_activist: res?.results?.is_activist,
        });

        const common = (name, id) => {
          if (name === "currency") {
            let details = colorLIst?.find((i) => i.value == id);

            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }
          if (name === "post") {
            let details = castList?.find((i) => i.value == id);

            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }
          if (name === "profession") {
            let details = profList?.find((i) => i.value == id);

            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }
        };

        setFormOrderName({
          ...formOrderName,
          colour: common("currency", res?.results?.colour),
          profession: common("profession", res?.results?.profession),
          post: common("post", res?.results?.post),
        });

        setIsEdit(true);
        setIsOpen(true);
        setFormError("");
        setUpdateId(res?.results?.epic_number);
      } else {
      }
    },
  });

  const handleUpdate = async (data) => {
    const validationResult = await checkFormError(
      { ...formValue },
      addResearchSchema
    );
    const payload = {
      formdata: data,
      id: updateId,
    };
    const payloadEpc = {
      formdata: data,
      id: epicNo,
    };

    if (epicNo) {
      updateEpicMutation.mutate(payloadEpc);
    } else {
      updateEpicMutation.mutate(payload);
    }
  };

  const addMutation = useMutation(addFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setFilter({
          ...filter,
          status: 1,
        });
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const updateEpicMutation = useMutation(updateEpicFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
        setEpicNo("");
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteFullVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteAcMutation = useMutation(deleteFullVoterAc, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getFullVoter");
    setIsOpen(false);
    setIsUpload(false);
    setIsDelete(false);
    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const handlePageClickModal = async (data) => {
    const currentPage = data.selected;
    setFilterModal((s) => ({
      ...s,
      offset: currentPage * filterModal.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);

    setDeleteId(data.uuid);
  };

  const hendleAllCancle = (data, flag) => {
    setOpenDel(true);
    setAll(true);
  };

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  /// ----------- add Branch and product ---------

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <div className="Tab_dropList">
          <Tabs
            TabList={fullVoterTabList}
            handleActive={handleActive}
            activeTab={filter.status}
          />
          <div className="flex_assambly_drop">
            <div>
              <Dropdown
                className="dropdownoforder"
                placeholder="Select Assembly Number"
                name="assembly_number"
                options={assamblyList ? assamblyList : []}
                valueText="value"
                labelText="label"
                isClearable={true}
                // isMulti={true}
                selectedOption={
                  formOrderName?.assemblyFilter
                    ? formOrderName?.assemblyFilter
                    : userLogin?.user_type !== 1 && assamblyList[0]
                }
                setSelectedOption={(data) => {
                  // handleOrderChange("driver", data.value);
                  console.log("data", data);
                  setFilter((s) => ({
                    ...s,
                    ...obj,
                    assembly_number: data?.value,
                  }));
                  // setFilter({...filter,assembly_number:data.value})
                  setFormOrderName({ ...formOrderName, assemblyFilter: data });
                }}
              />
            </div>
            <div>
              <Dropdown
                className="dropdownoforder"
                placeholder="Select Booth Number"
                name="booth_number"
                options={boothList ? boothList : []}
                valueText="value"
                labelText="label"
                isClearable={true}
                // isMulti={true}
                selectedOption={formOrderName?.boothFilter}
                setSelectedOption={(data) => {
                  // handleOrderChange("driver", data.value);
                  console.log("data", data);
                  setFilter((s) => ({
                    ...s,
                    ...obj,
                    booth_number: data?.value,
                  }));
                  // setFilter({...filter,assembly_number:data.value})
                  setFormOrderName({ ...formOrderName, boothFilter: data });
                }}
              />
            </div>
          </div>
        </div>
        <div className="checkBox_Cnacle">
          {selectedValues.length > 0 && (
            <button className="addPlan" onClick={() => hendleAllCancle()}>
              Cancel
            </button>
          )}
          {userLogin?.user_type !== 3 && (
            <button className="addPlan" onClick={() => setIsDelete(true)}>
              Delete By Ac
            </button>
          )}
          {userLogin?.user_type !== 3 && (
            <button className="addPlan" onClick={() => setIsUpload(true)}>
              Upload
            </button>
          )}
          {userLogin?.user_type !== 3 && (
            <button className="addPlan" onClick={() => setIsOpen(true)}>
              Add Voter
            </button>
          )}
        </div>
      </div>

      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>
          {/* <div className="inr_flex_mini ">
            <InputField
              placeholder="Booth Number"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  booth_number: e.target.value,
                }))
              }
            />
          </div> */}
          <div className="inr_flex_mini ">
            <InputField
              placeholder="Epic No."
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  epic_number: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="First Name"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  first_name_english: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="Last Name"
              className="anvSearch_input"
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  last_name_english: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select Colour"
              className="dropdownoforder orderModal_Input"
              name="colour"
              options={colorLIst ? colorLIst : []}
              valueText="value"
              labelText="label"
              isClearable={true}
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.colourFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, colour: data?.value });
                setFormOrderName({ ...formOrderName, colourFilter: data });
              }}
            />{" "}
          </div>
          {/* <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select Voted"
              className="dropdownoforder orderModal_Input"
              name="voted"
              options={votedLIst ? votedLIst : []}
              valueText="value"
              labelText="label"
              isClearable={true}
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.votedFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, voted: data?.value });
                setFormOrderName({ ...formOrderName, votedFilter: data });
              }}
            />{" "}
          </div> */}
          {/* <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select Cast"
              className="dropdownoforder orderModal_Input"
              name="cast"
              options={castList ? castList : []}
              valueText="value"
              labelText="label"
              isClearable={true}
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.castFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, cast: data?.value });
                setFormOrderName({ ...formOrderName, castFilter: data });
              }}
            />{" "}
          </div> */}
          {/* <div className="inr_flex_mini">
            {" "}
            <Dropdown
              placeholder="Select prof.."
              className="dropdownoforder orderModal_Input"
              name="prof"
              options={profList ? profList : []}
              valueText="value"
              labelText="label"
              isClearable={true}
              //   focus={!!formError?.driver}
              //   error={formError.driver}
              selectedOption={formOrderName?.profFilter}
              setSelectedOption={(data) => {
                // handleOrderChange("driver", data.value);
                setFilter({ ...filter, profession: data?.value });
                setFormOrderName({ ...formOrderName, profFilter: data });
              }}
            />{" "}
          </div> */}
          {/* <div className="inr_flex_mini ">
            <InputField
              placeholder="Mobile Number"
              className="anvSearch_input"
              maxLength={10}
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  mobile_number: e.target.value,
                }))
              }
            />
          </div> */}
        </div>
      </div>

      <div className="">
        <div className="table-responsive">
          <CustomReactTable
            columns={column}
            data={rowDataList(
              dataList?.results?.length ? dataList?.results : []
            )}
            tableLoading={loaderForList}
            type={"fullVoter"}
          />
          {/* } */}
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <CustomPagination
          onPageChange={handlePageClick}
          currentPage={filter?.currentPage}
          pageCount={Math.ceil(dataList?.count / filter?.limit)}
        />
      )}

      {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
            clearAll();
            setIsEdit(false);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) => onOrderSubmit(e)}
          title={isEdit ? "Edit Voter" : "Add Voter"}
          submitLabel={isEdit ? "Update Voter" : "Create Voter"}
          inputProps={
            <div className="anvOt_gridBx ">
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Sr Number" : "Sr Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Sr Number "
                  name="sr_number"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.sr_number}
                  error={formError.sr_number}
                  value={formOrderValue?.sr_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Assembly Number" : "Assembly Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Assembly Number"
                  name="assembly_number"
                  maxLength={4}
                  onKeyDown={handleKeyDown}
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.assembly_number}
                  error={formError.assembly_number}
                  value={formOrderValue?.assembly_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Booth Number" : "Booth Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Booth Number"
                  name="booth_number"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.booth_number}
                  error={formError.booth_number}
                  value={formOrderValue?.booth_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Epic Number" : "Epic Number"}
                  labelClass="FormLableKaran"
                  placeholder="Epic Number"
                  name="epic_number"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.epic_number}
                  error={formError.epic_number}
                  value={formOrderValue?.epic_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Voter Serial Number" : "Voter Serial Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Voter Serial Number"
                  name="voter_sr_number"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.voter_sr_number}
                  error={formError.voter_sr_number}
                  value={formOrderValue?.voter_sr_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "House Number" : "House Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter House Number"
                  name="house_number"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.house_number}
                  error={formError.house_number}
                  value={formOrderValue?.house_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "App Name" : "App Name"}
                  labelClass="FormLableKaran"
                  placeholder="Enter App Name"
                  name="app_name"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.app_name}
                  error={formError.app_name}
                  value={formOrderValue?.app_name}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Party Name" : "Party Name"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Party Name"
                  name="party_name"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.party_name}
                  error={formError.party_name}
                  value={formOrderValue?.party_name}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_outerFull">
                <div className="anvOt_inputField ">
                  <InputField
                    className="modal_input orderModal_Input"
                    label={isEdit ? "Age" : "Age"}
                    labelClass="FormLableKaran"
                    placeholder="Enter Age"
                    name="age"
                    type="text"
                    labelSpan="*"
                    maxLength={2}
                    disabled={formOrderValue?.voter_type == 1}
                    onKeyDown={handleKeyDown}
                    focus={!!formError?.age}
                    error={formError.age}
                    value={formOrderValue?.age}
                    onChange={({ target: { name, value } }) =>
                      handleOrderChange(name, value)
                    }
                  />
                </div>
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Full Name English" : "Full Name English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Full Name English"
                  name="full_name_english"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.full_name_english}
                  error={formError.full_name_english}
                  value={formOrderValue?.full_name_english}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Full Name Hindi" : "Full Name Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Full Name Hindi"
                  name="full_name_hindi"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.full_name_hindi}
                  error={formError.full_name_hindi}
                  value={formOrderValue?.full_name_hindi}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "First Name English" : "First Name English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter First Name English"
                  name="first_name_english"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.first_name_english}
                  error={formError.first_name_english}
                  value={formOrderValue?.first_name_english}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "First Name Hindi" : "First Name Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter First Name Hindi"
                  name="first_name_hindi"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.first_name_hindi}
                  error={formError.first_name_hindi}
                  value={formOrderValue?.first_name_hindi}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Middle Name English" : "Middle Name English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter middle Name English"
                  name="middle_name_english"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.middle_name_english}
                  error={formError.middle_name_english}
                  value={formOrderValue?.middle_name_english}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Middle Name Hindi" : "Middle Name Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Middle Name Hindi"
                  name="middle_name_hindi"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.middle_name_hindi}
                  error={formError.middle_name_hindi}
                  value={formOrderValue?.middle_name_hindi}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Last Name English" : "Last Name English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Last Name English"
                  name="last_name_english"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.last_name_english}
                  error={formError.last_name_english}
                  value={formOrderValue?.last_name_english}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Last Name Hindi" : "Last Name Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Last Name Hindi"
                  name="last_name_hindi"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.last_name_hindi}
                  error={formError.last_name_hindi}
                  value={formOrderValue?.last_name_hindi}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Gender English" : "Gender English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Gender English"
                  name="gender_english"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.gender_english}
                  error={formError.gender_english}
                  value={formOrderValue?.gender_english}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Gender Hindi" : "Gender Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Gender Hindi"
                  name="gender_hindi"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.gender_hindi}
                  error={formError.gender_hindi}
                  value={formOrderValue?.gender_hindi}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Address English" : "Address English"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Address English"
                  name="address_english"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.address_english}
                  error={formError.address_english}
                  value={formOrderValue?.address_english}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Address Hindi" : "Address Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Address Hindi"
                  name="address_hindi"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.address_hindi}
                  error={formError.address_hindi}
                  value={formOrderValue?.address_hindi}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={
                    isEdit ? "Both Address English" : "Both Address English"
                  }
                  labelClass="FormLableKaran"
                  placeholder="Enter Address English"
                  name="booth_address_english"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.booth_address_english}
                  error={formError.booth_address_english}
                  value={formOrderValue?.booth_address_english}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Both Address Hindi" : "Both Address Hindi"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Address Hindi"
                  name="booth_address_hindi"
                  type="text"
                  labelSpan="*"
                  disabled={formOrderValue?.voter_type == 1}
                  focus={!!formError?.booth_address_hindi}
                  error={formError.booth_address_hindi}
                  value={formOrderValue?.booth_address_hindi}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Mobile Number" : "Mobile Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Mobile Number"
                  name="mobile_number"
                  type="text"
                  labelSpan="*"
                  maxLength={10}
                  onKeyDown={handleKeyDown}
                  focus={!!formError?.mobile_number}
                  error={formError.mobile_number}
                  value={formOrderValue?.mobile_number}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <span>
                  <Label className="FormLableKaran">Color</Label>
                </span>
                <div>
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Color"
                    name="color"
                    options={colorLIst ? colorLIst : []}
                    valueText="value"
                    labelText="label"
                    // components={{ Menu: CustomControl }}
                    focus={!!formError?.colour}
                    error={formError?.colour}
                    selectedOption={formOrderName?.colour}
                    setSelectedOption={(data) => {
                      setFormOrderName({ ...formOrderName, colour: data });
                      handleOrderChange("colour", data.value);
                    }}
                  />
                </div>
              </div>
              <div className="anvOt_inputField ">
                <span>
                  <Label className="FormLableKaran">Profession</Label>
                </span>
                <div>
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Profession"
                    name="profession"
                    options={profList ? profList : []}
                    valueText="value"
                    labelText="label"
                    // components={{ Menu: CustomControl }}
                    focus={!!formError?.profession}
                    error={formError?.profession}
                    selectedOption={formOrderName?.profession}
                    setSelectedOption={(data) => {
                      setFormOrderName({ ...formOrderName, profession: data });
                      handleOrderChange("profession", data.value);
                    }}
                  />
                </div>
              </div>
              <div className="anvOt_inputField ">
                <span>
                  <Label className="FormLableKaran">Post</Label>
                </span>
                <div>
                  <Dropdown
                    className="dropdownoforder"
                    placeholder="Select Post"
                    name="post"
                    options={castList ? castList : []}
                    valueText="value"
                    labelText="label"
                    // components={{ Menu: CustomControl }}
                    focus={!!formError?.post}
                    error={formError?.post}
                    selectedOption={formOrderName?.post}
                    setSelectedOption={(data) => {
                      setFormOrderName({ ...formOrderName, post: data });
                      handleOrderChange("post", data.value);
                    }}
                  />
                </div>
              </div>

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Updated Address" : "Updated Address"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Address"
                  name="updated_address"
                  type="text"
                  focus={!!formError?.updated_address}
                  error={formError.updated_address}
                  value={formOrderValue?.updated_address}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>

              <div className="anvOt_outerFull">
                <div className="anvOt_inputField checkboxtext">
                  <InputField
                    className="modal_input "
                    labelClass="FormLableKaran"
                    placeholder="Company Registration Number"
                    name="is_activist"
                    type="checkbox"
                    focus={!!formError?.is_activist}
                    error={formError.is_activist}
                    value={formOrderValue?.is_activist}
                    checked={formOrderValue.is_activist === true}
                    onChange={({ target: { name, checked } }) => {
                      handleOrderChange(name, checked);
                    }}
                  />
                  <sapn className="checkboxTextbox">Activist</sapn>
                </div>
              </div>
              <div className="anvOt_outerFull">
                <div className="anvOt_inputField checkboxtext">
                  <InputField
                    className="modal_input "
                    labelClass="FormLableKaran"
                    placeholder="Company Registration Number"
                    name="is_voted"
                    type="checkbox"
                    focus={!!formError?.is_voted}
                    error={formError.is_voted}
                    value={formOrderValue?.is_voted}
                    checked={formOrderValue.is_voted === true}
                    onChange={({ target: { name, checked } }) => {
                      handleOrderChange(name, checked);
                    }}
                  />
                  <sapn className="checkboxTextbox">Voted</sapn>
                </div>
              </div>
            </div>
          }
        />
      )}

      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
      <Modal size="md" isOpen={openFleet} backdrop keyboard centered={true}>
        <ModalHeader toggle={() => isOpenFleet(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody> Are you sure you want to delete </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => isOpenFleet(false)}>
            Cancel
          </Button>
          <Button color="primary">Submit</Button>
        </ModalFooter>
      </Modal>

      <InputModal
        isOpen={isUpload}
        headerName={isEdit ? "Upload" : "Upload"}
        toggle={() => setIsUpload(!isUpload)}
        submitLabel={isEdit ? "Upload " : "Upload"}
        onSubmit={(e) => onSubmitUpload(e)}
        disabled={isEdit ? inputValue.excel_file === disableInp.excel_file : ""}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={"File"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Select File"
            name="excel_file"
            type="file"
            focus={!!inputError.excel_file}
            error={inputError.excel_file}
            // value={inputValue.excel_file}
            onChange={({ target: { name, files } }) =>
              handleOnChange(name, files[0])
            }
          />
        }
      />
      <InputModal
        isOpen={isDelete}
        headerName={isEdit ? "Delete By Ac" : "Delete By Ac"}
        toggle={() => setIsDelete(!isDelete)}
        submitLabel={isEdit ? "Delete " : "Delete"}
        onSubmit={(e) => onSubmitDelete(e)}
        disabled={!inputValue?.ac_no}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={"Enter AC No"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="Select File"
            name="ac_no"
            maxLength={4}
            onKeyDown={handleKeyDown}
            focus={!!inputError.ac_no}
            error={inputError.ac_no}
            // value={inputValue.excel_file}
            onChange={({ target: { name, value } }) =>
              handleOnChange(name, value)
            }
          />
        }
      />
    </>
  );
};
