import React from "react";
import { useTable, useSortBy } from "react-table";
import "./customReactTable.css";
import mytruck from "../../../image/tanker.png"


export const CustomReactTable = (props) => {
  const { data, columns, boxClass, tableLoading, noDataText ,type} = props;
  
  
  const table = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    table;
  return (
    
       <div className="tabFix ">
      {/* Apply the table props */}
      <table className="tabNew"  {...getTableProps()}>
        <thead className="table_head_style">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column) => (
                // Aplicamos las propiedades de ordenación a cada columna
                <th
                  {...column?.getHeaderProps(column?.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "desc"
                        : "asc"
                      : ""
                  }
                >
                  {column?.render("Header")}
                  <span>
                    {column?.isSorted
                      ? column?.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}

        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            tableLoading || rows?.length <= 0 ? (
              <tr >
                {tableLoading ? (
                    <td colSpan={columns.length} className="span_of_table">
                        <div className={type=="fullVoter"?"loaderGifleft":"loaderGif"}>
                    {" "}
                    Loadding ...
                    {/* <img src={mytruck} alt="" /> */}
                  </div>
                    </td>
                  
                ) : (
                  <td colSpan={columns.length} className={type=="fullVoter"?"span_of_table2":"span_of_table"}>
                    No Data
                  </td>
                )}
              </tr>
            ) : (
              rows?.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row?.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row?.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            )
          }
        </tbody>
      </table>
    </div>
   
   
  );
};
