import {
  FormFeedback,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";

export const InputField = (props) => {
  const {
    error,
    label,
    focus,
    preAddon = false,
    postAddon = false,
    labelClass,
    boxClass,
    labelSpan,
    labelSpanClass,
    preAddonClass,
    maxLength,
    type,
    rows,
    disabled,
    innerRef,
    style,
    onKeyDown,
    data,
    showPassword,
    showHandle

  } = props;
  const invalid = !!(focus && error);
  const valid = !!(focus && !error);

  return (
    <FormGroup className={boxClass}>
      {label && (
        <Label className={labelClass}>
          {label}
          <span className={labelSpanClass}>{labelSpan}</span>
        </Label>
      )}
      <InputGroup className="anvTxt_fielW">

        {preAddon && <InputGroupText>{preAddon}</InputGroupText>}
        <Input invalid={invalid} valid={valid} {...props} autoComplete="off" style={style} type={type} rows={rows} disabled={disabled} innerRef={innerRef} onKeyDown={onKeyDown} />

        {data !== "password" && <button className="fltBtn">{data?.data?.unit_details?.name}</button>}
        {data === "password" && (showPassword ? <BsEye className="fltBtn2" onClick={showHandle} /> : <BsEyeSlash className="fltBtn2" onClick={showHandle} />)}
        {postAddon && <InputGroupText>{postAddon}</InputGroupText>}
      </InputGroup>
      {invalid && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};
