import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import logo from "../../../image/karanLogo.png";

export default function AuthLayout({ title, children }) {
  return (
    <Container>
      <Row>
        <Col>
          <Card className="login_card">
            <div className="login_form_head text-center border p-2">
              Election Easy

              <div>
                <p className="lgHead_para">
                  {/* It is a long establish fact that a render will be distracted */}
                </p>
              </div>
            </div>
            <CardBody className="login_form_body">{children}</CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
