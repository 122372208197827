import React from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { breadcrumbsMenu, firstLetterCapital } from "../../helper";

function Breadcrumbs(props) {
  const { active = "", action, activeLable = false, goback = false } = props;

  return (
    <div className="breadcrumb_container">
      <div className="breadcrumb_box">
        <Breadcrumb tag="nav" className="breadcrumb_menu">
          <h5>
            {activeLable
              ? firstLetterCapital(activeLable)
              : firstLetterCapital(active)}
          </h5>
          {breadcrumbsMenu[
            activeLable && active === "Q'nnaire Progress"
              ? "TaskProgressDetails"
              : active
          ]?.list?.map((item) => (
            <BreadcrumbItem
              onClick={() => item?.link === false && goback && goback()}
              active={item?.active}
              tag={item?.active ? "h6" : NavLink}
              to={item?.link}
            >
              {item?.name === "Task Details" || item?.name === "StartupsDetails"
                ? firstLetterCapital(activeLable)
                : firstLetterCapital(item.name)}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
        <div>{action && action}</div>
      </div>
    </div>
  );
}

export default Breadcrumbs;
