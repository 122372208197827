import React from "react";
import { FormGroup, Input } from "reactstrap";

const SwitchField = (props) => {
  const { label, value, onSwitchChange,disabled } = props;

  return (
    <FormGroup className="style_switch" switch>
      <span check>{label}</span>
      <Input type="switch" {...props} onChange={onSwitchChange} checked={value} disabled={disabled} className="switchInput" />
    </FormGroup>
  );
};

export default SwitchField;
