import React from "react";
import Select from "react-select";
import { FormFeedback } from "reactstrap";

const Dropdown = (props) => {
  const {
    placeholder,
    options,
    selectedOption,
    setSelectedOption,
    valueText,
    labelText,
    error,
    focus,
    boxClass,
    className,
    classNamePrefix,
    isMulti = false,
    style,
    disabled,
    components,
    onMenuScrollToBottom,
    isClearable

  } = props;
  const invalid = !!(focus && error);
  const valid = !!(focus && !error);

  return (
    <div className={boxClass}>
      <Select
        isMulti={isMulti}
        // hideSelectedOptions={!isMulti}
        className={className}
        styles={style
        }
        value={selectedOption}
        // value={
        //   typeof selectedOption === "string"
        //     ? options?.find((item) => item[valueText] === selectedOption)
        //     : selectedOption
        // }
        classNamePrefix={classNamePrefix}
        onChange={setSelectedOption}
        options={
          valueText === "value" && labelText === "label"
            ? options
            : options?.map((item) => ({
              value: item[valueText],
              label: item[labelText],
              data: item
            }))
        }
        menuPlacement="auto"
        maxMenuHeight={190}
        placeholder={placeholder}
        isDisabled={disabled}
        isClearable={isClearable}
        components={components}
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
      {invalid && <FormFeedback>{error}</FormFeedback>}
    </div>
  );
};

export default Dropdown;
