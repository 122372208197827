import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const InputModal = (props) => {
  const {
    className,
    customeClassName,
    isOpen,
    toggle,
    onSubmit,
    headerName,
    submitLabel,
    inputProps,
    disabled,
    size = "md",
    isLoadding,
    mapModal,
    sendFor,
    approveBtn,
    loaddingAproove,
    loaddingReject
  } = props;

  return (
    <div>
      <Modal
        size={size}
        isOpen={isOpen}
        toggle={toggle}
        className={className}
        backdrop
        keyboard
        centered={true}
      >
        <ModalHeader toggle={toggle}>{headerName}</ModalHeader>
        <ModalBody>
          <div className={customeClassName ? `${customeClassName}` : "ModalFlexDiv"}>
            <div className=" flex labelwithinput">{inputProps}</div>

          </div>
        </ModalBody>
        <ModalFooter className="footerOfModal">
          {isLoadding ? <Button
            className="ques_row_btn"
            // onClick={onSubmit}
            disabled={true}
          >
            Creating..
          </Button> : <>{ !mapModal && <Button
            className="ques_row_btn"
            onClick={onSubmit}
            disabled={disabled}
          >
            {submitLabel}
          </Button>}</>
          }
          {sendFor && <>
            {loaddingReject ? <Button
            className="ques_row_btn"
            // onClick={onSubmit}
            disabled={true}
          >
            Creating..
          </Button> : <>{ !mapModal && <Button
            className="ques_row_btn"
            onClick={approveBtn}
            disabled={disabled}
          >
            {sendFor}
          </Button>}</>
          }
          </>}

          {!mapModal && <Button color="" onClick={toggle}>
            Cancel
          </Button>}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InputModal;
