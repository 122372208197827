export const questionTypeList = [
  {
    label: "Text Box",
  },
  {
    label: "Text Area",
  },
  {
    label: "Single Choice",
  },
  {
    label: "Multi Choice",
  },
  {
    label: "Drop Down",
  },
  {
    label: "Attachment",
  },
  {
    label: "Date",
  },
  {
    label: "Numeric",
  },
];

const dashboard = { name: "Dashboard", link: "/dashboard", active: false };
const questionnaire = {
  name: "Questionnaire",
  link: "/questionnaire",
  active: false,
};
const community = {
  name: "community",
  link: "/community",
  active: false,
};
const news = {
  name: "news",
  link: "/news",
  active: false,
};

export const breadcrumbsMenu = {
  "User Role": {
    key: "user_role",
    list: [dashboard, { name: "Users Role", link: "/", active: true }],
  },
  Users: {
    key: "user",
    list: [dashboard, { name: "Users", link: "/", active: true }],
  },
  Branch: {
    key: "branch",
    list: [dashboard, { name: "Branch", link: "/", active: true }],
  },
  "Category": {
    key: "category",
    list: [dashboard, { name: "Category", link: "/", active: true }],
  },
  "Ai-tool": {
    key: "ai-tools",
    list: [dashboard, { name: "Ai-tool", link: "/", active: true }],
  },
  Community: {
    key: "community",
    list: [dashboard, { name: "Community", link: "/", active: true }],
  },

  "Add Community": {
    key: "",
    list: [
      dashboard,
      community,
      { name: "Add Community", link: "/", active: true },
    ],
  },
  Persona: {
    key: "persona",
    list: [dashboard, { name: "Persona", link: "/", active: true }],
  },

  News: {
    key: "news",
    list: [dashboard, { name: "News", link: "/", active: true }],
  },

  "Add News": {
    key: "",
    list: [dashboard, news, { name: "Add News", link: "/", active: true }],
  },

  "Question Bank": {
    key: "question_bank",
    list: [dashboard, { name: "Question Bank", link: "/", active: true }],
  },
  Questionnaire: {
    key: "questionnair",
    list: [dashboard, { name: "Questionnaire", link: "/", active: true }],
  },
  "Add Questionnaire": {
    key: "",
    list: [
      dashboard,
      questionnaire,
      { name: "Add Questionnaire", link: "/", active: true },
    ],
  },
  "Q'nnaire Progress": {
    key: "",
    list: [dashboard, { name: "Q'nnaire Progress", link: "/", active: true }],
  },
  TaskProgressDetails: {
    key: "",
    list: [
      dashboard,
      { name: "Q'nnaire Progress", link: "/questionnaire-progress" },
      { name: "Task Details", link: "/", active: true },
    ],
  },
  "Add Question": {
    key: "",
    list: [
      dashboard,
      {
        name: "Questionnaire",
        link: "/questionnaire",
        active: false,
      },
      { name: "Question Sections", link: false, active: false },
      { name: "Add Question", link: "/", active: true },
    ],
  },
  "Question Section": {
    key: "",
    list: [
      dashboard,
      {
        name: "Questionnaire",
        link: "/questionnaire",
        active: false,
      },
      { name: "Question Sections", link: "/", active: true },
    ],
  },
  Community: {
    key: "community",
    list: [dashboard, { name: "Community", link: "/", active: true }],
  },
  Tags: {
    key: "tags",
    list: [dashboard, { name: "Tags", link: "/", active: true }],
  },
  Members: {
    key: "members",
    list: [dashboard, { name: "Members", link: "/", active: true }],
  },
  Research: {
    key: "research",
    list: [dashboard, { name: "research", link: "/", active: true }],
  },
  Post: {
    key: "post",
    list: [dashboard, { name: "post", link: "/", active: true }],
  },
  Industry: {
    key: "industry",
    list: [dashboard, { name: "industry", link: "/", active: true }],
  },

  "Add Community": {
    key: "",
    list: [
      dashboard,
      community,
      { name: "Add Community", link: "/", active: true },
    ],
  },

  Branch: {
    key: "branch",
    list: [dashboard, { name: "Branch", link: "/", active: true }],
  },
  "Document Type": {
    key: "documentType",
    list: [dashboard, { name: "Document Type", link: "/", active: true }],
  },
  Country: {
    key: "country",
    list: [dashboard, { name: "Country", link: "/", active: true }],
  },
  "Fleet Type": {
    key: "fleetType",
    list: [dashboard, { name: "Fleet Type", link: "/", active: true }],
  },
  Product: {
    key: "product",
    list: [dashboard, { name: "Product", link: "/", active: true }],
  },
  Currency: {
    key: "currency",
    list: [dashboard, { name: "currency", link: "/", active: true }],
  },
  Unit: {
    key: "unit",
    list: [dashboard, { name: "unit", link: "/", active: true }],
  },
  Expense: {
    key: "expense-category",
    list: [dashboard, { name: "Expense category", link: "/", active: true }],
  },
  "Fleet Group": {
    key: "Fleet-Group",
    list: [dashboard, { name: "Fleet Group", link: "/", active: true }],
  },
  "Fuel Station Api": {
    key: "FuelStationApi",
    list: [dashboard, { name: "Fuel Station Api", link: "/", active: true }],
  },
};

const action = [
  { name: "view", label: "View" },
  { name: "add", label: "Add" },
  { name: "change", label: "Edit" },
  { name: "delete", label: "Delete" },
];

export const constant = {
  roleActionList: {
    backEnd: [
      {
        name: "Dashboard",
        key: "dashboard",
        action: false,
      },
      
    ],
    frontEnd: [
      {
        name: "Fill Audit Form",
        key: "fill_audit_form",
      },
      {
        name: "Submit Audit Form",
        key: "submit_audit_form",
      },
      {
        name: "Approve Compliance (Auditor)",
        key: "approve_compliance",
      },
      {
        name: "Response to Compliance (Branch)",
        key: "response_for_compliance",
      },
    ],
  },
  sidebarArr: [
    {
      name: "Dashboard",
      key: "Dashboard",
      path: "/dashboard",
      logo: (
        <>
          <img src="/Images/Dashboard.svg" alt="" />
        </>
      ),
    },
    {
      name: "App",
      key: "app",
      path: "/app",
      logo: (
        <>
          <img src="/Images/Tools.svg" alt="" />
        </>
      ),
    },
    {
      name: "User List",
      key: "voter",
      path: "/voter",
      logo: (
        <>
          <img src="/Images/Tools.svg" alt="" />
        </>
      ),
    },
    
    {
      name: "Full Voter",
      key: "fullVoter",
      path: "/fullVoter",
      logo: (
        <>
          <img src="/Images/Tools.svg" alt="" />
        </>
      ),
    },
    {
      name: "Cast & Profession",
      key: "cast_pro",
      path: "/cast_pro",
      logo: (
        <>
          <img src="/Images/Tools.svg" alt="" />
        </>
      ),
    },
  ],
};
export const constant1 = {

  sidebarArr: [
    
    {
      name: "User List",
      key: "voter",
      path: "/voter",
      logo: (
        <>
          <img src="/Images/Tools.svg" alt="" />
        </>
      ),
    },
   
    {
      name: "Full Voter",
      key: "fullVoter",
      path: "/fullVoter",
      logo: (
        <>
          <img src="/Images/Tools.svg" alt="" />
        </>
      ),
    },
    
  ],
};

export const constant2 = {

  sidebarArr: [
   
    {
      name: "Full Voter",
      key: "fullVoter",
      path: "/fullVoter",
      logo: (
        <>
          <img src="/Images/Tools.svg" alt="" />
        </>
      ),
    },
    
  ],
};

export const orderTabList = [
  {
    label: "User LIst",
    value: 1
  },
 

]

export const sortVoterTabList = [
  {
    label: "App LIst",
    value: 1
  },
 

]

export const fullVoterTabList = [
  {
    label: "Full Voter LIst",
    value: 1
  },
 

]

export const dashboradTabList = [
  {
    label: "Dashboard",
    value: 1
  },
 

]

export const dashboradSecondTabList = [
  {
    label: "All",
    value: 1
  },
  {
    label: "Running",
    value: 2
  },
  {
    label: "Stopped",
    value: 3
  }, 
  {
    label: "No Device",
    value: 4
  }, 

]

export const customerTabList = [
  {
    label: "Customer",
    value: 1
  },

]

export const routeTabList = [
  {
    label: "Route",
    value: 1
  },

]

export const userTabList = [
  {
    label: "User",
    value: 1
  },

]

export const roleTabList = [
  {
    label: "Role",
    value: 1
  },

]

export const addCustomerTabList = [
  {
    label: 'Customer Details',
    value: 1
  },
  {
    label: 'Address',
    value: 2
  },
  {
    label: 'Attach Contract',
    value: 3
  },

]

export const addUserTabList = [
  {
    label: 'User Details',
    value: 1
  },
  {
    label: 'Branch',
    value: 2
  },

]

export const addRouteTabList = [
  {
    label: 'Route Details',
    value: 1
  },
  {
    label: 'Expenses',
    value: 2
  },
  {
    label: 'Fuel',
    value: 3
  },

]

export const fleetsTabList = [
  {
    label: 'All Fleet',
    value: 1
  }, {
    label: 'Available',
    value: 2
  }, {
    label: 'Nominated',
    value: 3
  }, {
    label: 'Intransit',
    value: 4
  }, {
    label: 'At Destination',
    value: 5
  }, {
    label: 'Disabled',
    value: 6
  },
  {
    label: 'In transit & Nomitnated',
    value: 7
  },

]

export const fuelTranstionList = [
  {
    label: 'Requested',
    value: 1
  }, {
    label: 'Low Balance',
    value: 2
  }, {
    label: 'Success',
    value: 3
  }, {
    label: 'Completed',
    value: 4
  },
]

export const approveList = [
  {
    label: 'Request',
    value: 1
  }, {
    label: 'Approved',
    value: 2
  }, {
    label: 'Cancelled',
    value: 3
  }
]
export const addfleetsTabList = [
  {
    label: 'Fleet Details',
    value: 1
  },
  {
    label: 'Document Details',
    value: 2
  },
  {
    label: 'Cross border Permits',
    value: 3
  },

]

export const trailerTabList = [
  {
    label: 'Trailers',
    value: 1
  },

]

export const addTrailerTabList = [
  {
    label: 'Trailer Details',
    value: 1
  },
  {
    label: 'Document Details',
    value: 2
  },
  {
    label: 'Cross border Permits',
    value: 3
  },

]

export const locationTabList = [
  {
    label: 'Location',
    value: 1
  },

]

export const fuelStationTabList = [
  {
    label: 'Fuel Station',
    value: 1
  },

]

export const driverTabList = [
  {
    label: 'Driver',
    value: 1
  },

]

export const driverDetailsTabList = [
  {
    label: 'Driver Details',
    value: 1
  },
  {
    label: 'Transactions',
    value: 2
  },

]

export const tripDetailsTabList = [
  {
    label: 'Trip Details',
    value: 1
  },
  {
    label: 'Expenses',
    value: 2
  },
  {
    label: 'P&L',
    value: 3
  },
  {
    label: 'Timeline',
    value: 4
  },
  {
    label: 'Route Expenses',
    value: 5
  },
  

]


export const nominationTabList = [
  {
    label: 'Nominated',
    value: 1
  },
  {
    label: 'Approved from Customer',
    value: 2
  },
]


export const TrailerTypelist = [{
  label: "LIQUID",
  value: 1
}, {
  label: "SOLID",
  value: 2
}]

export const tripsTabList = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Nomination',
    value: 1
  },
  {
    label: 'Doc. Ready',
    value: 2
  },
  {
    label: 'At Port Entry',
    value: 3
  },
  {
    label: 'At Loading',
    value: 4
  },
  {
    label: 'Fund Allocation',
    value: 5
  },
  {
    label: 'In Transit',
    value: 6
  },
  {
    label: 'At Destination',
    value: 7
  },
  {
    label: 'Delivered',
    value: 8
  },
  {
    label: 'In Return',
    value: 9
  },
  {
    label: 'Completed',
    value: 10
  },
  {
    label: 'Cancelled',
    value: 11
  },

]



export const mainRole = [

  {
    allow_permission: 1,
    category_id: {
      category_type: 1,
      createdAt: "2022-10-31T14:26:58.275Z",
      name: "Main Right",
      order: 1,
      updatedAt: "2022-10-31T14:26:58.275Z",
      _id: "635fdb326ed32394c0060ee0"
    },
    create: 1,
    delete: 1,
    edit: 1,
    full_access: 1,
    icon: "anvIcon_dashboard",
    is_parent: 0,
    label: "Permission",
    link: "/dashboard",
    menu_type: 1,
    order: 1,
    parent_id: null,
    permissions: { view: 1, create: 1, edit: 1, delete: 1 },
    status: 1,
    view: 1,
    __v: 0,
    _id: "63777c25b77ed545a88b41ff"
  },
  {
    allow_permission: 1,
    category_id: {
      category_type: 1,
      createdAt: "2022-10-31T14:26:58.275Z",
      name: "Main Right",
      order: 1,
      updatedAt: "2022-10-31T14:26:58.275Z",
      _id: "635fdb326ed32394c0060ee0"
    },
    create: 1,
    delete: 1,
    edit: 1,
    full_access: 1,
    icon: "anvIcon_dashboard",
    is_parent: 0,
    label: "User",
    link: "/dashboard",
    menu_type: 1,
    order: 1,
    parent_id: null,
    permissions: { view: 1, create: 1, edit: 1, delete: 1 },
    status: 1,
    view: 1,
    __v: 0,
    _id: "63a1ba84c478d62598198056"
  },
  {
    allow_permission: 1,
    category_id: {
      category_type: 1,
      createdAt: "2022-10-31T14:26:58.275Z",
      name: "Main Right",
      order: 1,
      updatedAt: "2022-10-31T14:26:58.275Z",
      _id: "635fdb326ed32394c0060ee0"
    },
    create: 1,
    delete: 1,
    edit: 1,
    full_access: 1,
    icon: "anvIcon_dashboard",
    is_parent: 0,
    label: "Role",
    link: "/dashboard",
    menu_type: 1,
    order: 1,
    parent_id: null,
    permissions: { view: 1, create: 1, edit: 1, delete: 1 },
    status: 1,
    view: 1,
    __v: 0,
    _id: "63b5120eaef8905ebba8c9de"
  },

]