import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import InputModal from "../../Component/Common/ConstomModal/InputModal";
import { InputField } from "../../Component/Common/InputField";
import { addBranchApi, getBranch, getTags } from "../../services";
import { toast } from "react-toastify";
import {
  addOrderValidation,
  addResearchSchema,
  addSortVoterValidation,
  modalInputSchema,
  productModalSchema,
} from "../../helper/errorSchema";
import {
  checkFormError,
  dateForXlsx,
  formatDate,
  formatDateList,
  handleKeyDown,
  orderTabList,
  sortVoterTabList,
} from "../../helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResposeError } from "../../helper";
import useDebounce from "../../helper/customhook";
import CustomPagination from "../../Component/Common/CustomPagination";
import CustomTable from "../../Component/Common/CustomTable";
import Dropdown from "../../Component/Common/Dropdown";
import { useNavigate } from "react-router-dom";
import CustomOffcanvas from "../../Component/Common/CustomOffcanvas";
import {
  deleteResearch,
  getAllCustomer,
  singleNewDetails,
  updateActiveResearch,
  updateResearch,
} from "../../services/researchService";
import ConfirmModal from "../../Component/Common/ConstomModal/ConfirmModal";
import ImageCropper from "../../Component/Common/ImageCropper";
import { Tabs } from "../../Component/Common/Tabs";
import { CustomDatePicker } from "../../Component/Common/ConstomModal/CustomDatePicker";
import AllocateVehicleModal from "../../Component/Common/AllocateVehicleModal";
import { getDocumentType } from "../../services/documentTypeService";
import { getCountry } from "../../services/countryService";
import { getFleetType } from "../../services/fleetTypeService";
import {
  acceptAndRejectStatus,
  addFleetsForOrder,
  addOrder,
  deleteOrder,
  getAllFleetforOrder,
  getAllOrder,
  getdryCargo,
  gettankersCsv,
  singleOrderDetails,
  updateActiveOrder,
  updateOrder,
} from "../../services/orderService";
import { addProductApi, getProduct } from "../../services/productService";
import { getAllDriver } from "../../services/driverService";
import mytruck from "../../../image/tanker.png";
import {
  getAllDrop,
  getAllPeckup,
  getAllPickup,
} from "../../services/locationService";
import SwitchField from "../../Component/Common/SwitchField";
import EditIcon from "../../../image/Group15.png";
import DeleteIcon from "../../../image/deleteIcon1.png";
import { getAllRoute, singleRouteDetails } from "../../services/routeService";
import { components } from "react-select";
import { getUnit } from "../../services/unitService";
import CommonRoute from "../../Component/Common/CommonRoute";
import {
  addExitBorderApi,
  getExitBorder,
} from "../../services/exitBorderService";
import { CommonCustomer } from "../../Component/Common/CommonCustomer";
import { getStorage, setStorage } from "../../helper/storage";
import { CustomReactTable } from "../../Component/Common/CustomReactTable";
import {
  addSortVoter,
  deleteSortVoter,
  getSortVoter,
  singleSortVoter,
  updateActiveSortVoter,
  updateSortVoter,
} from "../../services/voterService";
import UserContext from "../../helper/UserContext";

const productTypeList = [
  { label: "Liquid", value: 1 },
  { label: "Dry", value: 2 },
];

export const SortVoter = () => {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    customer_name: "",
  };
  const branchObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const fleetObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const productObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const customerObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const driverObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const routeObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };
  const borderObj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
    limit: "all",
  };

  const formObj = {
    number: "",
    date: formatDate(new Date()),
  };

  const orderObj = {
    app_name: "",
    party_name: "",
    banner_send_date: formatDate(new Date(), "-"),
    error_message: "",
    promotion_message: "",
    message_two: "",
    message_one: "",
    assembly_number: "",
    banner_image:"",
    is_enable: false,
    is_banner_enable: false,
  };
  const orderObjName = {
    app_name: "",
    party_name: "",
    banner_send_date: formatDate(new Date(), "-"),
    error_message: "",
    promotion_message: "",
    message_two: "",
    message_one: "",
    assembly_number: "",
    banner_image:"",
    is_enable: false,
    is_banner_enable: false,
  };

  const userLogin = getStorage("logged-in");
  const result = userLogin?.user_branches?.join(",");
  const { user, setUser } = useContext(UserContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openFleet, isOpenFleet] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [allotedisOpen, setAllotedisOpen] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [imageType, setimageType] = useState("");
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState({
    limit: 20,
    status: status,
    branch_uuid: result,
    customer_id: userLogin?.user_type === 2 ? userLogin?.id : "",
    branch_uuid: userLogin?.user_branches?.map((obj) => obj).join(","),
    ...obj,
  });

  const [filterModal, setFilterModal] = useState({
    limit: 100,
    status: status,
    ...obj,
  });

  const [formValue, setFormValue] = useState(formObj);
  const [formOrderValue, setFormOrderValue] = useState(orderObj);
  const [formOrderName, setFormOrderName] = useState(orderObjName);
  const [initialForm, setInitialForm] = useState(orderObj);
  const [formError, setFormError] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [preview, setPreview] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [branchList, setBranchList] = useState();
  const [fleetList, setFleetList] = useState();
  const [driverList, setDriverList] = useState();
  const [routeList, setRouteList] = useState();
  const [productList, seProductList] = useState();
  const [filterFleet, setFilterFleet] = useState();
  const [filterFleetPagi, setFilterFleetPagi] = useState();
  const [selectTrailerList, setSelectTrailerList] = useState([]);
  const [downloadcsv, setDownload] = useState("");
  const [routeValue, setRouteValue] = useState(1);
  const [selectTraler, setSelectTraler] = useState();
  const [OrderID, setOrderID] = useState({
    id: 1,
    qty: "",
  });
  const [indexOfFleet, setIndexOfFleet] = useState();
  const debouncedFilter = useDebounce(filter, 500);
  const [loaderForList, setLoaderForList] = useState(false);


  const [selectedValues, setSelectedValues] = useState([]);
  const [all, setAll] = useState(false);

  const userTypeList = [
    { label: "ADMIN", value: 1 },
    { label: "VOLUNTEER", value: 2 },
  ];

  const assmblyList = [
    { label: "20", value: 20 },
    { label: "46", value: 46 },
    { label: "50", value: 50 },
    { label: "6", value: 6 },
  ];

  const column = [
    {
      Header: "Serial No.",
      accessor: "serial",
    },
    {
      Header: "App Name",
      accessor: "app_name",
    },
    {
      Header: "Party Name",
      accessor: "party_name",
    },
    {
      Header: "Banner Date",
      accessor: "banner_send_date",
    },

    {
      Header: "Promotion Message",
      accessor: "promotion_message",
    },
    {
      Header: "Error Message",
      accessor: "error_message",
    },

    {
      Header: "Action",
      accessor: "action",
    },
  ];
  const rowDataList = (data) => {
    const listArray = data?.map((item, i) => ({
      ...item,
      serial: <>{i + 1}</>,
      order_numbers: item?.order_number,
      status: <div className="statusofOrder"></div>,
      route1: "",

      product: "",
      date: formatDateList(item?.added_on),
      qty: `${item?.qty} ${
        item?.product_detail?.product_type === 1 ? "Ltr" : "Ton"
      }`,
      nominated: item?.trip_data?.nominated,
      atDestination: item?.trip_data?.at_destination,
      at_loading: item?.trip_data?.at_loading,
      at_port_entry: item?.trip_data?.at_port_entry,
      cancel: item?.trip_data?.cancel,
      completed: item?.trip_data?.completed,
      delivered: item?.trip_data?.delivered,
      document_ready: item?.trip_data?.document_ready,
      fund_allocation: item?.trip_data?.fund_allocation,
      in_return: item?.trip_data?.in_return,
      in_transit: item?.trip_data?.in_transit,
      action: (
        <>
          <div className="actionBtn">
            <SwitchField
              onSwitchChange={() => updateTagStatus(item)}
              value={item?.is_enable}
              disabled={userLogin?.user_type === 2}
            />
            {userLogin?.user_type === 1 && (
              <img
                src={EditIcon}
                alt=""
                onClick={() => handleEditAction(item)}
              />
            )}
            {userLogin?.user_type === 1 && (
              <img
                src={DeleteIcon}
                alt=""
                onClick={() => hendleDeleteTag(item, true)}
              />
            )}
          </div>
        </>
      ),
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));
    return listArray;
  };

  const { data: dataList, isSuccess } = useQuery(
    ["getSortVoter", debouncedFilter],
    () => getSortVoter(debouncedFilter, filter),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataList) {
      setLoaderForList(false);
    } else {
      setLoaderForList(true);
    }
  }, [dataList]);

  const clearAll = () => {
    setFormOrderValue({
      app_name: "",
      party_name: "",
      banner_send_date: formatDate(new Date(), "-"),
      error_message: "",
      promotion_message: "",
      message_two: "",
      message_one: "",
      assembly_number: "",
      banner_image:"",
      is_enable: false,
      is_banner_enable: false,
    });
    setFormOrderName({
      app_name: "",
      party_name: "",
      banner_send_date: formatDate(new Date(), "-"),
      error_message: "",
      promotion_message: "",
      message_two: "",
      message_one: "",
      assembly_number: "",
      banner_image:"",
      is_enable: false,
      is_banner_enable: false,
    });

    setIsEdit(false);
    setFormValue("");
    setFormError("");
  };

  const setValidationError = (error) => {
    setFormError({
      ...error,
    });
  };

  const updateTagStatus = (item) => {
    const payload = {
      id: item.uuid,
      is_enable: !item?.is_enable,
    };
    updateActiveMutation.mutate(payload);
  };

  const handleOrderChange = async (name, value) => {
    const stateObj = { ...formOrderValue, [name]: value };

    setFormOrderValue(stateObj);

    if (!!formError) {
      const error = await checkFormError(stateObj, addSortVoterValidation);
      setValidationError(error);
    }
  };

  const onOrderSubmit = async () => {
    const validationResult = await checkFormError(
      { ...formOrderValue },
      addSortVoterValidation
    );
    const formData = new FormData();
    Object.keys(formOrderValue).map((item, index) => {
      if (formOrderValue[item] !== initialForm[item]) {
        formData.append(item, formOrderValue[item]);
       
      }
      if (item === "banner_send_date") {
        formData.append("banner_send_date", formOrderValue[item]);
      }
    });
    const payload = {
      app_name: formOrderValue?.app_name,
      party_name: formOrderValue?.party_name,
      banner_send_date: formatDate(new Date(), "-"),
      error_message: formOrderValue?.error_message,
      promotion_message: formOrderValue?.promotion_message,
      message_two: formOrderValue?.message_two,
      message_one: formOrderValue?.message_one,
      assembly_number: formOrderValue?.assembly_number,
      is_enable: formOrderValue?.is_enable,
      banner_image:formOrderValue?.banner_image,
      is_banner_enable: formOrderValue?.is_banner_enable,
    };

    console.log(payload)
    const updatePayload = {};

    Object.keys(formOrderValue).map((item, index) => {
      if (formOrderValue[item] !== initialForm[item]) {
        // formData.append(item, formOrderValue[item]);

        updatePayload[item] = formOrderValue[item];

        if (item === "assembly_number") {
          updatePayload["assembly_number"] = formOrderValue?.assembly_number;
        }
      }
    });

    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
        handleUpdate(formData);
      } else {
        handleAdd(formData);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const updateActiveMutation = useMutation(updateActiveSortVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const handleAdd = (formData) => {
    addMutation.mutate(formData);
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteId);
  };

  const handleEditAction = (item) => {
    getOrderDetails.mutate(item?.uuid);
  };
  const getOrderDetails = useMutation(singleSortVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        setFormOrderValue({
          app_name: res?.results?.app_name,
          party_name: res?.results?.party_name,
          banner_send_date: res?.results.banner_send_date,
          error_message: res?.results.error_message,
          promotion_message: res?.results.promotion_message,
          message_two: res?.results?.message_two,
          message_one: res?.results?.message_one,
          assembly_number: res?.results?.assembly_number,
          is_enable: res?.results.is_enable,
          is_banner_enable:res?.results?.is_banner_enable,


        });

        setInitialForm({
          app_name: res?.results?.app_name,
          party_name: res?.results?.party_name,
          banner_send_date: res?.results.banner_send_date,
          error_message: res?.results.error_message,
          promotion_message: res?.results.promotion_message,
          message_two: res?.results?.message_two,
          message_one: res?.results?.message_one,
          assembly_number: res?.results?.assembly_number,
          is_enable: res?.results.is_enable,
          is_banner_enable:res?.results?.is_banner_enable
        });

        const common = (name, id) => {
          if (name === "currency") {
            let details = userTypeList?.find((i) => i.value === id);
            return {
              data: details,
              label: details?.label,
              value: details?.value,
            };
          }
        };

        setFormOrderName({
          ...formOrderName,
          assembly_number: res?.results?.assembly_number?.map((item) => {
            return { label: item, value: item };
          }),
        });

        setIsEdit(true);
        setIsOpen(true);
        setFormError("");
        setUpdateId(res?.results?.uuid);
      } else {
      }
    },
  });

  const handleUpdate = async (data) => {
    const validationResult = await checkFormError(
      { ...formValue },
      addResearchSchema
    );
    const payload = {
      formdata: data,
      id: updateId,
    };

    updateMutation.mutate(payload);
  };

  const addMutation = useMutation(addSortVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setFilter({
          ...filter,
          status: 1,
        });
      } else {
        onErrorResponse(res?.error);
        setIsLoadding(false);
      }
    },
  });

  const updateMutation = useMutation(updateSortVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        setIsEdit(false);
        onSuccessResponse(res?.message);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const deleteMutation = useMutation(deleteSortVoter, {
    onSuccess: (res) => {
      if (res?.success) {
        onSuccessResponse(res?.message);
        setOpenDel(false);
      } else {
        onErrorResponse(res?.error);
      }
    },
  });

  const onSuccessResponse = (message) => {
    toast.success(message ? message : "success", { position: "bottom-left" });
    queryClient.refetchQueries("getSortVoter");

    setIsOpen(false);
    setIsLoadding(false);
    clearAll();
  };

  const onErrorResponse = (error) => {
    toast.error(
      getResposeError(error ? error : "server error", {
        position: "bottom-left",
      })
    );
    setIsLoadding(false);
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected;
    setFilter((s) => ({
      ...s,
      offset: currentPage * filter.limit,
      currentPage: currentPage,
    }));
  };

  const handlePageClickModal = async (data) => {
    const currentPage = data.selected;
    setFilterModal((s) => ({
      ...s,
      offset: currentPage * filterModal.limit,
      currentPage: currentPage,
    }));
  };

  const hendleDeleteTag = (data, flag) => {
    setOpenDel(flag);

    setDeleteId(data.uuid);
  };

  const hendleAllCancle = (data, flag) => {
    setOpenDel(true);
    setAll(true);
  };

  const handleActive = (data) => {
    setLoaderForList(true);
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const [inputValue, setInputValue] = useState({
    name: "",
    banner: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
    banner: "",
  });
  const [disableInp, setDisableInp] = useState({
    name: "",
    banner: "",
  });

  const onSubmit = async () => {
    const validationResult = await checkFormError(
      { name: inputValue.name },
      modalInputSchema
    );
    if (validationResult === true) {
      setIsLoadding(true);
      if (isEdit === true) {
      } else {
        setStorage("app_name", inputValue.name);
        setUser({ ...user, appName: inputValue.name });
        setIsLoadding(false);
        setIsOpenModal(false);
      }
    } else {
      setInputError(validationResult);
      setIsLoadding(false);
    }
  };

  const onSubmitBanner = async () => {
    console.log(inputValue.banner);
    setIsLoadding(true);
    setUser({ ...user, banner: URL.createObjectURL(inputValue.banner) });
    setIsLoadding(false);
    setAllotedisOpen(false);
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      const error = await checkFormError(stateObj, modalInputSchema);
      setInputError(error);
    }
  };
  /// ----------- add Branch and product ---------

  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={sortVoterTabList}
          handleActive={handleActive}
          activeTab={filter.status}
        />
        <div className="checkBox_Cnacle">
          {selectedValues.length > 0 && (
            <button className="addPlan" onClick={() => hendleAllCancle()}>
              Cancel
            </button>
          )}

          {userLogin?.user_type === 1 && (
            <button className="addPlan" onClick={() => setIsOpenModal(true)}>
              Change App Name
            </button>
          )}
          {/* {userLogin?.user_type === 1 && (
            <button className="addPlan" onClick={() => setAllotedisOpen(true)}>
              Chnage Banner
            </button>
          )} */}
          {userLogin?.user_type === 1 && (
            <button className="addPlan" onClick={() => setIsOpen(true)}>
              Add App
            </button>
          )}
        </div>
      </div>

      <div className="anvTask_prog">
        <div className="anvInputs&fillters">
          <div className="inr_flex_filter">
            <button className="filterBox">Filter</button>
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="App Name"
              className="anvSearch_input"
              value={filter?.app_name}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  app_name: e.target.value,
                }))
              }
            />
          </div>
          <div className="inr_flex_mini ">
            <InputField
              placeholder="Party Name"
              className="anvSearch_input"
              value={filter?.party_name}
              onChange={(e) =>
                setFilter((s) => ({
                  ...s,
                  ...obj,
                  party_name: e.target.value,
                }))
              }
            />
          </div>
        </div>
      </div>

      <div className="table table_hieght">
        <div className="table-responsive">
          <CustomReactTable
            columns={column}
            data={rowDataList(
              dataList?.results?.length ? dataList?.results : []
            )}
            tableLoading={loaderForList}
          />
          {/* } */}
        </div>
      </div>
      {dataList?.results?.length === 0 ? (
        ""
      ) : (
        <CustomPagination
          onPageChange={handlePageClick}
          currentPage={filter?.currentPage}
          pageCount={Math.ceil(dataList?.count / filter?.limit)}
        />
      )}

      {isOpen && (
        <CustomOffcanvas
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
            clearAll();
            setIsEdit(false);
          }}
          isLoadding={isLoadding}
          onSubmit={(e) => onOrderSubmit(e)}
          title={isEdit ? "Edit App" : "Add App"}
          submitLabel={isEdit ? "Update App" : "Create App"}
          inputProps={
            <div className="anvOt_gridBx ">
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "App Name" : "App Name"}
                  labelClass="FormLableKaran"
                  placeholder="Enter App Name"
                  name="app_name"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.app_name}
                  error={formError.app_name}
                  value={formOrderValue?.app_name}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Party Name" : "Party Name"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Party Name"
                  name="party_name"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.party_name}
                  error={formError.party_name}
                  value={formOrderValue?.party_name}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <div>
                  <span>
                    <Label className="FormLableKaran">Banner Send Date</Label>
                  </span>
                  <div>
                    <CustomDatePicker
                      value={formOrderName?.banner_send_date}
                      max={formatDate(new Date())}
                      type="date"
                      focus={!!formError?.banner_send_date}
                      error={formError.banner_send_date}
                      name="banner_send_date"
                      onSelect={({ target: { name, value } }) => {
                        handleOrderChange(name, value);
                        setFormOrderName({
                          ...formOrderName,
                          banner_send_date: value,
                        });
                      }}
                      className="date-input"
                      // onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                      // className="date-input"
                    />
                  </div>
                </div>
              </div>

              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Error Message" : "Error Message"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Error Message"
                  name="error_message"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.error_message}
                  error={formError.error_message}
                  value={formOrderValue?.error_message}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Promotion Message" : "Promotion Message"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Promotion Message"
                  name="promotion_message"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.promotion_message}
                  error={formError.promotion_message}
                  value={formOrderValue?.promotion_message}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Message One" : "Message One"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Message One"
                  name="message_one"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.message_one}
                  error={formError.message_one}
                  value={formOrderValue?.message_one}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Message Two" : "Message Two"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Message Two"
                  name="message_two"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.message_two}
                  error={formError.message_two}
                  value={formOrderValue?.message_two}
                  onChange={({ target: { name, value } }) =>
                    handleOrderChange(name, value)
                  }
                />
              </div>
              <div className="anvOt_inputField ">
               
                <InputField
                  className="modal_input orderModal_Input"
                  label={isEdit ? "Assembly Number" : "Assembly Number"}
                  labelClass="FormLableKaran"
                  placeholder="Enter Assembly Number"
                  name="assembly_number"
                  type="text"
                  labelSpan="*"
                  focus={!!formError?.assembly_number}
                  error={formError.assembly_number}
                  value={formOrderValue?.assembly_number}
                  onChange={({ target: { name, value } }) => {
                    // console.log(value.split(","))
                    handleOrderChange(name, value.split(","));
                    // setFormOrderValue({...formOrderValue,value})
                  }}
                />
              </div>
              <div className="anvOt_inputField ">
               
               <InputField
                 className="modal_input orderModal_Input"
                 label={isEdit ? "Edit Banner Upload" : "Banner Upload"}
                 labelClass="FormLableKaran"
                 placeholder="Banner upload"
                 name="banner_upload"
                 type="file"
                 labelSpan="*"
                //  focus={!!formError?.banner_upload}
                //  error={formError.banner_upload}
                //  value={formOrderValue?.banner_upload}
                 onChange={({ target: { name, files } }) => {
                  setFormOrderValue({ ...formOrderValue, banner_image: files[0] });
                }}
               />
             </div>
              <div className="anvOt_outerFull">
                <div className="anvOt_inputField checkboxtext">
                  <InputField
                    className="modal_input "
                    labelClass="FormLableKaran"
                    placeholder="Company Registration Number"
                    name="is_enable"
                    type="checkbox"
                    focus={!!formError?.is_enable}
                    error={formError.is_enable}
                    value={formOrderValue?.is_enable}
                    checked={formOrderValue.is_enable === true}
                    onChange={({ target: { name, checked } }) => {
                      handleOrderChange(name, checked);
                    }}
                  />
                  <sapn className="checkboxTextbox"> Enable</sapn>
                </div>
              </div>
              <div className="anvOt_outerFull">
                <div className="anvOt_inputField checkboxtext">
                  <InputField
                    className="modal_input "
                    labelClass="FormLableKaran"
                    placeholder="Company Registration Number"
                    name="is_banner_enable"
                    type="checkbox"
                    focus={!!formError?.is_banner_enable}
                    error={formError.is_banner_enable}
                    value={formOrderValue?.is_banner_enable}
                    checked={formOrderValue.is_banner_enable === true}
                    onChange={({ target: { name, checked } }) => {
                      handleOrderChange(name, checked);
                    }}
                  />
                  <sapn className="checkboxTextbox">Banner Enable</sapn>
                </div>
              </div>
            </div>
          }
        />
      )}

      <InputModal
        isOpen={isOpenModal}
        headerName={isEdit ? "Edit App Name" : "App Name"}
        toggle={() => setIsOpenModal(!isOpenModal)}
        submitLabel={isEdit ? "Update App Name" : "Continue"}
        onSubmit={(e) => onSubmit(e)}
        disabled={isEdit ? inputValue.name === disableInp.name : ""}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={isEdit ? "Edit App Name" : "App Name"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="App Name"
            name="name"
            focus={!!inputError.name}
            error={inputError.name}
            value={inputValue.name}
            onChange={({ target: { name, value } }) =>
              handleOnChange(name, value)
            }
          />
        }
      />
      <InputModal
        isOpen={allotedisOpen}
        headerName={isEdit ? "Edit Banner" : "App Banner"}
        toggle={() => setAllotedisOpen(!allotedisOpen)}
        submitLabel={isEdit ? "Update App Banner" : "Continue"}
        onSubmit={(e) => onSubmitBanner(e)}
        disabled={isEdit ? inputValue.banner === disableInp.banner : ""}
        isLoadding={isLoadding}
        inputProps={
          <InputField
            className="modal_input"
            label={isEdit ? "Edit App Banner" : "App Banner"}
            labelClass="modal_label"
            labelSpan="*"
            labelSpanClass="style_for_star"
            placeholder="App Banner"
            name="banner"
            type="file"
            // focus={!!inputError.banner}
            // error={inputError.banner}
            // value={inputValue.banner}
            onChange={({ target: { name, files } }) => {
              setInputValue({ ...inputValue, banner: files[0] });
            }}
          />
        }
      />

      <ConfirmModal
        isOpen={openDel}
        toggle={() => setOpenDel(!openDel)}
        onSubmit={() => handleDelete()}
      />
      <Modal size="md" isOpen={openFleet} backdrop keyboard centered={true}>
        <ModalHeader toggle={() => isOpenFleet(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody> Are you sure you want to delete </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => isOpenFleet(false)}>
            Cancel
          </Button>
          <Button color="primary">Submit</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
