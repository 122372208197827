import React, { useContext, useEffect, useState } from "react";
import { NavItem, Nav } from "reactstrap";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../image/karanLogo.png";
import "./Sidebar.css";
import { constant, constant1, constant2 } from "../../helper";
import SubMenu from "./SubMenu";
import { getStorage } from "../../helper/storage";
import banner from "../../../image/banner.jpg"
import UserContext from "../../helper/UserContext";

const Sidebar = ({ isOpen, toggle }) => {
  const [settingToggle, setSettingToggle] = useState("");
  const [newPermission,setNewPermission] = useState()
  const userLogin = getStorage("logged-in")
  const AppName = getStorage("app_name")
  const navigate = useNavigate()
  const {user,setUser} = useContext(UserContext)
  console.log(user)

  useEffect(() => {
    setSettingToggle(window.location.pathname);
  }, [window.location.pathname]);

useEffect(()=>{
if(userLogin?.user_type !== 1){
  navigate((userLogin?.user_type == 3 ? constant2.sidebarArr:constant1.sidebarArr)[0]?.path)

}
},[])

  
  const filterSidebarList = constant.sidebarArr.filter(item => item.name !== 'Approval');
 
  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>
        <div className="sideBarLogo_div">
       {user.appName} 

        </div>
      </div>
      <div className="side-menu">
        
    
        <Nav vertical className="list-unstyled pb-3 pt-2">
          {(userLogin?.user_type == 1 ? constant.sidebarArr :userLogin?.user_type == 2 ? constant1?.sidebarArr:constant2.sidebarArr)?.map((item, i) => {

        // navigate(item);
      
          
            return (
              <>
                <NavItem key={item.path}>
                  {item?.Child?.length > 0 ? (
                    <button
                      onClick={() =>
                        setSettingToggle(
                          item?.Child?.some(
                            (child) => child?.path === settingToggle
                          ) || settingToggle === item.name
                            ? ""
                            : item.name
                        )
                      }
                      className={
                        (item?.Child?.some(
                          (child) => child?.path === settingToggle
                        ) || settingToggle === item.name
                          ? "active "
                          : "") + " nav-link"
                      }
                      key={i}
                      to={item.path}
                    >
                      <span className="sidebar_logo">{item.logo}</span>
                      <span>{item.name}</span>
                    </button>
                  ) : (
                    <Link
                      className={
                        (settingToggle === item.path ? "active " : "") +
                        " nav-link"
                      }
                      key={i}
                      to={item.path}
                    >
                      <span className="sidebar_logo">{item.logo}</span>
                      <span>{item.name}</span>
                    </Link>
                  )}
                  {(item?.Child?.some(
                    (child) => child?.path === settingToggle
                  ) ||
                    settingToggle === item.name) && (
                      <div className="anvSubmenu">
                        {item?.Child && (
                          <>
                            <Nav vertical className="list-unstyled pb-3 pt-2">
                              {item?.Child?.map((item, i) => (
                                <NavItem key={item.path}>
                                  <Link
                                    className={
                                      (settingToggle === item.path
                                        ? "active "
                                        : "") + " nav-link"
                                    }
                                    key={i}
                                    to={item.path}
                                  >
                                    <span>{item.name}</span>
                                  </Link>
                                </NavItem>
                              ))}
                            </Nav>
                          </>
                        )}
                      </div>
                    )}
                </NavItem>
              </>
            );
          })}
        </Nav>
        
       
        
      </div>
      {/* <div className="banner_div">
        <img  src={user?.banner?user?.banner:banner}/>
        </div> */}
      
    </div>
  );
};

export default Sidebar;
