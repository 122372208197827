export const methodType = {
  PUT: "put",
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PATCH: "patch",
};

export const apiEndPoints = {
  userLogin: "auth/login",
  dashData: "dashboard/dashboard-statistics",
  coreCategory: "core/category",
  authUserProfile: "auth/user-profile",
  aiTools: "tool/ai-tool",
  community: "social/community",
  research: "core/research",
  news: "core/news",
  post: "social/post",
  category: "core/category",
  country: "core/country",
  persona: "core/persona",
  industry: "core/industry",
  branch: "core/branch",
  documentType: "core/document-type",
  product: "fleet/product",
  fleetType: "core/fleet-type",
  customer: "auth/customer",
  fleet: "/fleet/fleet",
  tariler: "/fleet/trailer",
  crossBorder: "/fleet/cross-border-permit",
  order: "/order/order",
  driver: "/auth/driver",
  location: "/core/location",
  currency: "/core/currency",
  unit: "/core/unit",
  exitBorder: "/core/exit-border",
  fuelStation: "/core/fuel-station",
  expenseCateory: "/core/expense-category",
  unasign: "/fleet/fleet/unassigned_trailers",
  routes: "/fleet/route",
  trip: "/order/trip",
  driverTrans: "wallet/driver-transaction",
  driverWallet: "wallet/driver-wallet",
  user: "/auth/user",
  permission: "auth/permission",
  roles: "auth/role",
  tripReport:"order/trip_report",
  fleetGroup:"/core/fleet-group",
  fuelExpenseTrip:"/order/trip-fuel-expense",
  ApproveFuel:"/order/create-fuel-order",
  completeOrder:"/order/completed-fuel-order",
  gpsTrack:"fleet/gps-locations",
  triplog:"/order/trip-log",
  fuelStationApi:"/core/fuel-station-api",
  userVoter:"auth/user",
  sortVoter:"auth/app-details",
  fullVoter:"voter/full",
  epicfullVoter:"voter/full/update",
  forget:"auth/forget-password",
  reset:"auth/reset-password",
  excelVoter:"voter/upload_full_voter_file",
  booth:"voter/booth-number",
  castPro:"voter/cast",
  castExcle:"voter/cast/upload-excel",
  assembly:"auth/assembly-number",
  fullDeleteAc:"voter/full/delete-by-ac-no"
};
