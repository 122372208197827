import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Button, Modal, ModalBody, ModalFooter, Label } from "reactstrap";
import Dropdown from "../../Component/Common/Dropdown";
import CommonSignature from "../../Component/Common/CommonSignature";
import useDebounce from "../../helper/customhook";
import { dashboardDataRequest, getBranch } from "../../services";

import {
  dashboradSecondTabList,
  dashboradTabList,
  orderTabList,
} from "../../helper";
import { Tabs } from "../../Component/Common/Tabs";
import { InputField } from "../../Component/Common/InputField";
import SwitchField from "../../Component/Common/SwitchField";
import { CustomReactTable } from "../../Component/Common/CustomReactTable";

import GoogleMapComponent, {
  MyMapComponent,
} from "../../Component/Common/GoogleMapComponent";
import { getAllFleet, getMapTrackList } from "../../services/fleetTypeService";

export function Dashboard() {
  const obj = {
    offset: 0,
    currentPage: 0,
    search: "",
    ordering: "-id",
  };

  const [updatebranchList, setUpadteBranchList] = useState([]);
  const [open, setOpen] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [switchValue, setSwitchValue] = useState(false);
  const [activeTabFleet, setActiveTabFleet] = useState(1);
  const toggle = () => setOpen(!open);
  const [latLOng, setLatLOng] = useState();
  const [activeWindow, setActiveWindow] = useState("");
  const [mapAdress, setMapAdress] = useState([]);
  const [mapListOFList, setMapListOFList] = useState([]);
  const [fleetList,setFleetList] = useState([])
  const [updateMapArray,setUpdateMapArray] = useState()
  // console.log("updateMapArray",updateMapArray)
  const handleSelectChange = ({ target: { value } }) => {
    setFocusAfterClose(JSON.parse(value));
  };
  const [status, setStatus] = useState(1);

  const [filter, setFilter] = useState({
    dashboard_type: "Admin",
    limit: 20,
    status: status,
    ...obj,
  });

  const [subFilter, setSubFilter] = useState({

    status: status,
  
  });

  const debouncedFilter = useDebounce(filter, 500);
  const debouncedSubFilter = useDebounce(subFilter, 500);

  const { data, isSuccess } = useQuery(
    ["dashData", debouncedFilter],
    () => dashboardDataRequest(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );

  const { data: branchList } = useQuery(
    ["getBranch", debouncedFilter],
    () => getBranch(debouncedFilter, filter),
    { enabled: Boolean(debouncedFilter) }
  );

  const { data: mapTrackList } = useQuery(
    ["getMapTrackList", debouncedSubFilter],
    () => getMapTrackList(debouncedSubFilter,subFilter),
    { enabled: Boolean(debouncedSubFilter) }
  );

  const getFleetsList = async (data) => {
    const res = await getAllFleet(data)
    setFleetList(res?.results)

}
 
  

  useEffect(() => {
    
    const updatedArray1 =  mapTrackList?.results?.fleet_point_data.map((obj1,index) => {
      const matchingObj2 =  mapTrackList?.results?.fleet_statistics_data.find((obj2,index2) => index2 === index);
    
      if (matchingObj2) {
        // Add the "age" property to obj1
        return { ...obj1, ...matchingObj2 };
      }
    
      return obj1;
    });

    // console.log("updatedArray1",updatedArray1)
    
  setUpdateMapArray(updatedArray1)
    
    updatedArray1?.map((item, index) => {
      fetchAddress(
        item?.latend,
        item?.lngend,
        "AIzaSyAkWxbuO-maU16USeyELd3UP0hPLITP3Ec",
        index,
        updatedArray1
      );
    });
  }, [mapTrackList]);

  const fetchAddress = async (latitude, longitude, apiKey, index,updatedArray1) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );
      const data = await response.json();
    
      if (data.results && data.results.length > 0) {
        let newExpenseList = [...updatedArray1];
        newExpenseList[index][
          "address"
        ] = `${data.results[0].address_components[0].long_name}, ${data.results[0].address_components[1].long_name}, ${data.results[0].address_components[2].long_name} , ${data.results[0].address_components[3].long_name} , ( ${data.results[0].address_components[4].long_name} )`;
        
      await  setMapListOFList(newExpenseList);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  useEffect(() => {
    if (branchList?.results) allBranch(branchList);
  }, [branchList]);

  const column = [
    {
      Header: "Fleet Number",
      accessor: "truck_number",
    },
    {
      Header: "Fleet Movement",
      accessor: "spead_mov",
    },
    {
      Header: "Current Location",
      accessor: "address",
    },
    {
      Header: "Ignition",
      accessor: "ignition_en",
    },
    {
      Header: "Driver",
      accessor: "driver_name",
    },
    {
      Header: "Trip Assigned",
      accessor: "trip_number",
    },
  ];
  const columnFleet = [
    {
      Header: "Fleet Number",
      accessor: "truck_number",
    },
    
  ];
  const rowDataList = (data) => {
    const listArray = data?.map((item, i) => ({
      ...item,
      name: item?.name,
      designation: item?.designation,
      contact: item?.contact_number,
      spead_mov:item?.speed > 0 ?`${item?.speed}km/hr`:"Stop",
ignition_en:item?.speed > 0 ? "ON":"OFF", 
      sno: <div>{i + 1 + filter.limit * filter.currentPage}</div>,
      icon: (
        <>
          <img src={item?.icon} alt={item?.name} />
        </>
      ),
    }));

    return listArray;
  };
  const allBranch = async () => {
    const branchData = await branchList?.results?.map((item) => {
      return {
        id: item.id,
        is_active: item?.is_active,
        name: item?.name,
        response_message: item?.response_message,
      };
    });

    setUpadteBranchList([
      {
        id: "all",
        is_active: true,
        name: "All Branch",
        response_message: "All Branch",
      },
      ...branchData,
    ]);
  };

  const handleActive = (data) => {
    // setLoaderForList(true)
    console.log("main")
    setFilter({
      ...filter,
      status: data.value,
    });
  };

  const handleActiveFleet = (data) => {
    setActiveTabFleet(data?.value);
    
    if(data?.value==2){
      setSubFilter({
        status: data.value,
        running:true,
      });
    }
    if(data?.value==3){
      setSubFilter({
        status: data.value,
        stopped: true,
      });
    }
    if(data?.value==1){
      setSubFilter({
        status: data.value,
      });
    
    }

    if(data?.value==4){
      setSubFilter({
        status: data.value,
      });
      getFleetsList({no_device:true,limit:20})
    }
  };
  
  return (
    <>
      <div className="tabMain_head">
        {" "}
        <Tabs
          TabList={dashboradTabList}
          handleActive={handleActive}
          activeTab={filter.status}
        />
      </div>
      <div className="ques_container">
        <div className="dashboard_container">
          {filter.status === 1 && (
            <div className="dashbordCountBoxFirst">
              
            </div>
          )}
          {filter.status === 2 && (
            <div className="dashboard_iner_container">
              <div className="fleet_moniter_container">
                <div className="fleet_moiter">
                  <InputField
                    placeholder="Search"
                    className="anvSearch_input"
                    value={filter?.search}
                    onChange={(e) =>
                      setFilter((s) => ({
                        ...s,
                        ...obj,
                        search: e.target.value,
                      }))
                    }
                  />
                  <div className="customerStatus">
                    <SwitchField
                      onSwitchChange={(e) => setSwitchValue(e.target.checked)}
                      value={switchValue}
                    />
                    Show Map
                  </div>
                </div>

                <Tabs
                  customClassName="fleet_moniter_dash"
                  TabList={dashboradSecondTabList}
                  handleActive={handleActiveFleet}
                  activeTab={activeTabFleet}
                />
              </div>
              
             
            </div>
          )}
          
          
        </div>
      </div>
    </>
  );
}
