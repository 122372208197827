import { createBrowserRouter, Navigate } from "react-router-dom";
import { getStorage } from "./helper/storage";
import { routerList, PublicRoute, ProtectRoutes } from "./router";
import { useContext } from "react";
import UserContext from "./helper/UserContext";


const loggedIn = getStorage("logged-in");

let list = routerList?.map((route) => ({
  ...route,
  element: route.private ? (
    <ProtectRoutes>
      <route.element />
    </ProtectRoutes>
  ) : (
    <PublicRoute>
      <route.element />
    </PublicRoute>
  ),
}));

export const routers = createBrowserRouter(
  [
    {
      path: "/",
      element: <Navigate to={loggedIn ? "/dashboard" : "/login"} />,
    },
    ...list, // render public and private route
    {
      path: "*",
      element: <Navigate to={loggedIn ? "/dashboard" : "/login"} />,
    },
  ],
  {
    basename: "/",
  }
);

export default routers;
