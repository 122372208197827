import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getSortVoter = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.sortVoter, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const addSortVoter = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.sortVoter, data,  true, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const singleSortVoter = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.sortVoter + `/${data}`, {})
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const getAllAppDetailsApi = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.sortVoter, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const updateSortVoter = (data = {}) => {
    return performRequest(
        methodType.PATCH,
        apiEndPoints.sortVoter + `/${data?.id}`,
        data?.formdata,true,true
       
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteSortVoter = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.sortVoter + `/${data}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateActiveSortVoter = (data = {}) => {
    const payload = {
        is_enable: data?.is_enable
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.sortVoter + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


//--------------------


export const getFullVoter = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.fullVoter, data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const addFullVoter = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.fullVoter, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const singleFullVoter = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.fullVoter + `/${data}`, {})
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export const updateFullVoter = (data = {}) => {
    return performRequest(
        methodType.PATCH,
        apiEndPoints.fullVoter + `/${data?.id}`,
        data?.formdata,
        
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateEpicFullVoter = (data = {}) => {
    return performRequest(
        methodType.PATCH,
        apiEndPoints.epicfullVoter + `/${data?.id}`,
        data?.formdata,
        
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteFullVoter = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.fullVoter + `/${data}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const deleteFullVoterAc = (data = {}) => {
    console.log(data)
    return performRequest(
        methodType.DELETE,
        apiEndPoints.fullDeleteAc +`?ac_no=${data?.ac_no}&app_name=${data?.app_name}&party_name=${data?.party_name}`,false
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}



export const updateActiveFullVoter = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.fullVoter + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const uploadExcelVoterApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.excelVoter, data, true, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const getAllBoothApi = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.booth, data,true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getAllAssbApi = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.assembly, data,true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};