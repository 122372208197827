import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CustomPagination from "./CustomPagination";
import { InputField } from "./InputField";
import { IoMdClose } from "react-icons/io";

const AllocateVehicleModal = (props) => {



    const {
        className = "anvLarge_modal",
        isOpen,
        toggle,
        onSubmit,
        headerName,
        submitLabel,
        inputProps,
        disabled,
        size = "xl",
        toggle2,
        filterFleet,
        fleetList,
        callBackData,
        selectTrailerList,
        deleteHandle,
        sendNoninationHandle,
        deleteFleetHandle,
        OrderID,
        loaddingSend,
        setSearchKey,
        handlePageClick,
        filterFleetPagi,
        filterModal
    } = props;



    const [totalWeight, setTotalWeight] = useState(0)
    const [substect, setSubstract] = useState(0)

    const initialTotal = parseInt(OrderID.qty)


    useEffect(() => {
        totalValue()


    }, [selectTrailerList])

    useEffect(() => {

    }, [])

    const totalValue = () => {

        const sum = selectTrailerList?.reduce((accumulator, currentValue) => {
            return accumulator + parseInt(currentValue.totalCapacity);
        }, 0);

        setSubstract(initialTotal - sum)
        setTotalWeight(sum)
    }

    return (
        <Modal
            size={size}
            isOpen={isOpen}
            toggle={toggle}
            className={className}
            backdrop
            keyboard
            centered={true}>

            <ModalBody>
                <div className="mainTable_flex">
                    <div className="leftTable_bx">
                        <div className="allocateHead">Allocate Vehicle</div>
                        <div className="anvMod_tableContainer">
                            <table className="anvMod_table">
                                <thead>
                                    <tr>
                                        <th>Vehicle Number</th>
                                        <th>Capacity</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                {selectTrailerList && selectTrailerList?.map((item, index) => <tbody>
                                    <tr>
                                        <td>{item.truck_number}</td>
                                        <td>{item.totalCapacity}</td>
                                        <td onClick={() => deleteFleetHandle(index)}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM5 9H15C15.2652 9 15.5196 9.10536 15.7071 9.29289C15.8946 9.48043 16 9.73478 16 10C16 10.2652 15.8946 10.5196 15.7071 10.7071C15.5196 10.8946 15.2652 11 15 11H5C4.73478 11 4.48043 10.8946 4.29289 10.7071C4.10536 10.5196 4 10.2652 4 10C4 9.73478 4.10536 9.48043 4.29289 9.29289C4.48043 9.10536 4.73478 9 5 9Z" fill="#21272A" />
                                        </svg></td>
                                    </tr>


                                </tbody>)}
                            </table>
                        </div>
                    </div>

                    <div className="rightTable_bx">
                        <div className='tabBtn_bx'>
                            <div>
                                <button className='active'>Auto Suggestion</button>

                                <button>Intransit</button>
                                <button>Available</button>
                            </div>
                            <button onClick={toggle} className="closeIconeFont"><IoMdClose />
                            </button>
                        </div>
                        <div className="anvTask_prog">
                            <div className="anvInputs&fillters">
                                <div className="inr_flex">
                                    <InputField placeholder="Search" className="anvSearch_input" onChange={(e) => setSearchKey(e.target.value)} />
                                </div>
                            </div>
                        </div>


                        <div className="anvMod_tableContainer">
                            <table className="anvMod_table">
                                <thead>
                                    <tr>
                                        <th>Vehicle Number</th>
                                        <th>Fleet Type</th>
                                        <th>Capacity</th>
                                        <th>Driver</th>
                                        <th>Border Pass </th>
                                        <th align="center">Status</th>
                                        <th align="center">Action</th>
                                    </tr>
                                </thead>

                                {filterFleet && filterFleet?.map((item, index) => <tbody className="alloteFleetTable">
                                    <tr>
                                        <td>{item?.truck_number}</td>
                                        {/* <td>{fleetList.filter((i) => i.id === item?.fleet_type)[0]?.name}</td> */}
                                        <td>{item?.fleet_type_name}</td>
                                        <td>{item?.trailer_details?.reduce((accumulator, currentValue) => {
                                            return accumulator + parseInt(currentValue.tare_weight);
                                        }, 0)}</td>
                                        <td>{item?.driver_name}</td>
                                        <td>
                                            <div className="tickSvg">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <g clip-path="url(#clip0_481_616)">
                                                        <mask id="mask0_481_616" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                                            <path d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2412C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.3857 21.2411 8.17222C20.7385 6.95875 20.001 5.85656 19.071 4.92901C18.1435 3.99902 17.0413 3.26151 15.8278 2.75885C14.6143 2.25619 13.3135 1.99831 12 2.00001C10.6865 1.99833 9.3857 2.25623 8.17222 2.75889C6.95875 3.26154 5.85656 3.99904 4.92901 4.92901C3.99904 5.85656 3.26154 6.95875 2.75889 8.17222C2.25623 9.3857 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z" fill="white" stroke="white" stroke-width="4" stroke-linejoin="round" />
                                                            <path d="M8 12L11 15L17 9" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                                        </mask>
                                                        <g mask="url(#mask0_481_616)">
                                                            <path d="M0 0H24V24H0V0Z" fill="#0011AA" />
                                                        </g>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_481_616">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <g clip-path="url(#clip0_481_616)">
                                                        <mask id="mask0_481_616" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                                            <path d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2412C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.3857 21.2411 8.17222C20.7385 6.95875 20.001 5.85656 19.071 4.92901C18.1435 3.99902 17.0413 3.26151 15.8278 2.75885C14.6143 2.25619 13.3135 1.99831 12 2.00001C10.6865 1.99833 9.3857 2.25623 8.17222 2.75889C6.95875 3.26154 5.85656 3.99904 4.92901 4.92901C3.99904 5.85656 3.26154 6.95875 2.75889 8.17222C2.25623 9.3857 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z" fill="white" stroke="white" stroke-width="4" stroke-linejoin="round" />
                                                            <path d="M8 12L11 15L17 9" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                                        </mask>
                                                        <g mask="url(#mask0_481_616)">
                                                            <path d="M0 0H24V24H0V0Z" fill="#3DBE00" />
                                                        </g>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_481_616">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <g clip-path="url(#clip0_481_616)">
                                                        <mask id="mask0_481_616" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                                            <path d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2412C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.3857 21.2411 8.17222C20.7385 6.95875 20.001 5.85656 19.071 4.92901C18.1435 3.99902 17.0413 3.26151 15.8278 2.75885C14.6143 2.25619 13.3135 1.99831 12 2.00001C10.6865 1.99833 9.3857 2.25623 8.17222 2.75889C6.95875 3.26154 5.85656 3.99904 4.92901 4.92901C3.99904 5.85656 3.26154 6.95875 2.75889 8.17222C2.25623 9.3857 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z" fill="white" stroke="white" stroke-width="4" stroke-linejoin="round" />
                                                            <path d="M8 12L11 15L17 9" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                                        </mask>
                                                        <g mask="url(#mask0_481_616)">
                                                            <path d="M0 0H24V24H0V0Z" fill="#3DBE00" />
                                                        </g>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_481_616">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </td>
                                        <td align="center">
                                            <button className="statusBtn">Available</button>
                                        </td>
                                        <td align="center">
                                            <button className="modactionBtn" onClick={() => {
                                                toggle2()
                                                callBackData(item)
                                            }} >Allocate</button>
                                        </td>
                                    </tr>

                                </tbody>)}
                            </table>

                        </div>
                    </div>


                </div>
                {filterFleetPagi?.length === 0 ? (
                    ""
                ) : (
                    <CustomPagination
                        onPageChange={handlePageClick}
                        currentPage={filterModal?.currentPage}
                        pageCount={Math.ceil(filterFleetPagi?.count / filterModal?.limit)}
                    />
                )}
            </ModalBody>
            <ModalFooter className="anvModalFooter">

                <div className="anvFirst_sublistBx">
                    <div className="anvFirst_sublist">
                        <div>Order :</div>
                        <div>{`${initialTotal} ${OrderID?.type === 1 ? "Ltr" : "Ton"}`}</div>
                    </div>

                    <div className="anvFirst_sublist">
                        <div>Alloted :</div>
                        <div>{`${totalWeight} ${OrderID?.type === 1 ? "Ltr" : "Ton"}`}</div>
                    </div>

                    <div className="anvFirst_sublist">
                        <div>Pending :</div>

                        <div>{`${substect} ${OrderID?.type === 1 ? "Ltr" : "Ton"}`}</div>
                    </div>
                </div>

                <div className="otrTickBx">
                    <div className="avnTickFull_wid">
                        <div>
                            <div className="anvTick_tabFlex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_481_602)">
                                        <mask id="mask0_481_602" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                            <path d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2412C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.38569 21.2411 8.17221C20.7385 6.95874 20.001 5.85655 19.071 4.929C18.1435 3.99901 17.0413 3.2615 15.8278 2.75884C14.6143 2.25619 13.3135 1.9983 12 2C10.6865 1.99833 9.3857 2.25623 8.17222 2.75888C6.95875 3.26154 5.85656 3.99904 4.92901 4.929C3.99904 5.85655 3.26154 6.95874 2.75889 8.17221C2.25623 9.38569 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z" fill="white" stroke="white" stroke-width="4" stroke-linejoin="round" />
                                            <path d="M8 12L11 15L17 9" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                        </mask>
                                        <g mask="url(#mask0_481_602)">
                                            <path d="M0 0H24V24H0V0Z" fill="#0011AA" />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_481_602">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p>Fleet Border Pass- OK</p>
                            </div>


                            <div className="anvTick_tabFlex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_481_644)">
                                        <mask id="mask0_481_644" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                            <path d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2411C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.38568 21.2411 8.17221C20.7385 6.95873 20.001 5.85655 19.071 4.92899C18.1435 3.99901 17.0413 3.26149 15.8278 2.75884C14.6143 2.25618 13.3135 1.99829 12 1.99999C10.6865 1.99832 9.3857 2.25622 8.17222 2.75887C6.95875 3.26153 5.85656 3.99903 4.92901 4.92899C3.99904 5.85655 3.26154 6.95873 2.75889 8.17221C2.25623 9.38568 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z" fill="white" stroke="white" stroke-width="4" stroke-linejoin="round" />
                                            <path d="M8 12L11 15L17 9" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                        </mask>
                                        <g mask="url(#mask0_481_644)">
                                            <path d="M0 0H24V24H0V0Z" fill="#3DBE00" />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_481_644">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p>Trailer Border Pass- OK</p>
                            </div>
                        </div>

                        <div>

                            <div className="anvTick_tabFlex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_481_609)">
                                        <mask id="mask0_481_609" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                            <path d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2412C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.38569 21.2411 8.17221C20.7385 6.95874 20.001 5.85655 19.071 4.929C18.1435 3.99901 17.0413 3.2615 15.8278 2.75884C14.6143 2.25619 13.3135 1.9983 12 2C10.6865 1.99833 9.3857 2.25623 8.17222 2.75888C6.95875 3.26154 5.85656 3.99904 4.92901 4.929C3.99904 5.85655 3.26154 6.95874 2.75889 8.17221C2.25623 9.38569 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z" fill="white" stroke="white" stroke-width="4" stroke-linejoin="round" />
                                            <path d="M8 12L11 15L17 9" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                        </mask>
                                        <g mask="url(#mask0_481_609)">
                                            <path d="M0 0H24V24H0V0Z" fill="#68696C" />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_481_609">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p>Border Pass Not Available</p>
                            </div>
                            <div className="anvTick_tabFlex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_481_651)">
                                        <mask id="mask0_481_651" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                            <path d="M12 22C13.3135 22.0017 14.6143 21.7438 15.8278 21.2411C17.0413 20.7385 18.1435 20.001 19.071 19.071C20.001 18.1435 20.7385 17.0413 21.2412 15.8278C21.7438 14.6143 22.0017 13.3135 22 12C22.0017 10.6865 21.7438 9.38568 21.2411 8.17221C20.7385 6.95873 20.001 5.85655 19.071 4.92899C18.1435 3.99901 17.0413 3.26149 15.8278 2.75884C14.6143 2.25618 13.3135 1.99829 12 1.99999C10.6865 1.99832 9.3857 2.25622 8.17222 2.75887C6.95875 3.26153 5.85656 3.99903 4.92901 4.92899C3.99904 5.85655 3.26154 6.95873 2.75889 8.17221C2.25623 9.38568 1.99833 10.6865 2.00001 12C1.99831 13.3135 2.25619 14.6143 2.75885 15.8278C3.26151 17.0413 3.99902 18.1435 4.92901 19.071C5.85656 20.001 6.95875 20.7385 8.17222 21.2411C9.3857 21.7438 10.6865 22.0017 12 22Z" fill="white" stroke="white" stroke-width="4" stroke-linejoin="round" />
                                            <path d="M8 12L11 15L17 9" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                        </mask>
                                        <g mask="url(#mask0_481_651)">
                                            <path d="M0 0H24V24H0V0Z" fill="#BE0000" />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_481_651">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p>Border Pass Expired</p>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="otrSend_bx">
                    {loaddingSend ? <button className="sendNominate" disabled={true} >Sending...</button> : <button className="sendNominate" disabled={!(initialTotal <= totalWeight)} onClick={sendNoninationHandle}>Send for Nomination</button>}
                </div>

            </ModalFooter>
        </Modal>
    )
}

export default AllocateVehicleModal