import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getFleetType = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.fleetType, data, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};



export const getAllFleet = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.fleet, data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};



export const addFleetApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.fleet, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateFleet = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.fleet + `/${data?.id}`,
        data.formdata,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteFleetApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.fleet + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateActiveFleet = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.fleet + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const addFleetTypeApi = (data = {}) => {
    return performRequest(methodType.POST, apiEndPoints.fleetType, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateFleetTypeApi = (data = {}) => {
    return performRequest(
        methodType.PUT,
        apiEndPoints.fleetType + `/${data?.id}`,
        data,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


export const deleteFleetTypeApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.fleetType + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}


export const singleFleetDetails = (data = {}) => {

    return performRequest(methodType.GET, apiEndPoints.fleet + `/${data}`, {}, true)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}



export const updateActiveFleetType = (data = {}) => {
    const payload = {
        is_active: data?.is_active
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.fleetType + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

//------fleet group ------------
export const getFleetGroup = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.fleetGroup, data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};
export const addFleetCode = (data={})=>{
    return performRequest(methodType.POST, apiEndPoints.fleetGroup, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateFleetGroupApi = (data = {}) => {
    const payload = {
        name:data.name,
        fleet_code:data?.fleet_code
    }
    return performRequest(
        methodType.PATCH,
        apiEndPoints.fleetGroup + `/${data?.id}`,
        payload,
        true
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const deleteFleetGroupApi = (data = {}) => {
    return performRequest(
        methodType.DELETE,
        apiEndPoints.fleetGroup + `/${data?.id}`
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}


export const getMapTrackList = (data = {}) => {
    return performRequest(methodType.GET, apiEndPoints.gpsTrack, data, true)
        .then((response) => {

            return response;
        })
        .catch((error) => {
            return error;
        });
};
